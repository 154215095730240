import React, {Component} from 'react';
import LonjaIcon from '../../Assets/Images/lonja.png';
import LibertadorIcon from '../../Assets/Images/libertador.png';
import LaLonjaIcon from '../../Assets/Images/la-lonja.png';
import SuramericanaIcon from '../../Assets/Images/suramericana.png';
import DataCreditoIcon from '../../Assets/Images/datacredito@2x.png';
import {NavLink} from 'reactstrap';
import {NavLink as RRNavLink} from "react-router-dom";

class Footer extends Component <{}, {}> {

  render() {

    return (
      <div className=" d-flex flex-column  flex-wrap">
        <div className="align-self-center mb-4">
          <h3 className="text-primary">
            <b>Nuestros aliados</b>
          </h3>
        </div>
        <div className=" px-5 d-flex justify-content-center mb-5 flex-wrap align-items-center">
          {/*<img width="120" src={LonjaIcon} alt="..."/>*/}
          {/*<img width="200" src={LibertadorIcon} alt="..."/>*/}
          {/*<img width="130" src={LaLonjaIcon} alt="..."/>*/}
          {/*<img width="200" src={SuramericanaIcon} alt="..."/>*/}
          {/*<img width="200" src={DataCreditoIcon} alt="..."/>*/}


          <img width="120" src={LonjaIcon} alt="..."/>
          <img className="ml-5" width="200" src={LibertadorIcon} alt="..."/>
          <img className="ml-5" width="130" src={LaLonjaIcon} alt="..."/>
          <img className="ml-5" width="200" src={SuramericanaIcon} alt="..."/>
          <img className="ml-5" width="200" src={DataCreditoIcon} alt="..."/>


        </div>
        <footer className="footer navbar-dark px-5 py-4 d-flex text-white justify-content-center flex-wrap">
          <div className="navbar-nav mr-5">
            <span className="footer-title mb-3 d-inline-block">
              <b>Nosotros</b>
            </span>
            <div className="d-flex justify-content-between">
              <ul className="list-unstyled mr-3">
                <NavLink className="d-flex" to="/servicios" activeClassName="active" tag={RRNavLink}>
                  <span>Servicios</span>
                </NavLink>
                <NavLink className="d-flex" to="/sobre_ayura" activeClassName="active" tag={RRNavLink}>
                  <span>Sobre Ayurá</span>
                </NavLink>
                <NavLink className="d-flex" to="/valores" activeClassName="active" tag={RRNavLink}>
                  <span>Nuestros Valores</span>
                </NavLink>
              </ul>
            </div>
          </div>
          <div className="navbar-nav">
            <span className="footer-title mb-3 d-inline-block">
                <b>Contáctanos</b>
            </span>
            <ul className="list-unstyled">
              <NavLink className="d-flex" to="/contactanos" activeClassName="active" tag={RRNavLink}>
                <span>Información de contacto</span>
              </NavLink>
              <NavLink className="d-flex" to="/requisitos" activeClassName="active" tag={RRNavLink}>
                <span>Requisitos</span>
              </NavLink>
            </ul>
          </div>
        </footer>
        <footer className="footer px-5 d-flex text-white justify-content-center flex-wrap">
          <p>
            © Copyright 2019 Arrendamientos Ayurá. Todos los derechos reservados.
          </p>
        </footer>
      </div>
    );
  }
}

export default Footer;
