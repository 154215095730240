import React, {Component} from 'react';
import ModalComponent from '../../Shared/ModalComponent';

class PaymentComponent extends Component {

  getPaymentStatus(status) {
    switch (status) {
      case 1:
        return "Pago finalizado Ok";
      case 200:
        return "Pago iniciado";
      case 777:
        return "Pago declinado";
      case 888:
        return "Pago pendiente por iniciar";
      case 999:
        return "Pago pendiente por finalizar";
      case 4001:
        return "Pendiente por centrales de riesgo";
      case 4000:
        return "Rechazado por centrales de riesgo";
      case 4003:
        return "Error con las centrales de riesgo";
      case 1000:
      case 1002:
        return "Pago rechazado";
      case 1001:
        return "Error entre ACH y el Banco";
      default:
        return "Procesando";
    }
  }

  render() {
    const {paymentResults, onClose} = this.props;
    return (
      <ModalComponent
        header="Información del pago"
        body={

          <table className = "table table-sm table-striped table-bordered">
            <thead>
              <tr>
                <th scope = "row">Id</th>
                <th scope = "row">Nombre</th>
                <th scope = "row">Email</th>
                <th scope = "row">Estado</th>
              </tr>
            </thead>
            <tbody>
              {
                paymentResults.map(pr =>
                  <tr key={pr.payment_unique_id} className={pr.status === 1 && "bg-success text-white"}>
                    <td>{pr.payment_unique_id}</td>
                    <td>
                      {pr.first_names} {pr.last_names}
                    </td>
                    <td>{pr.email}</td>
                    <td>{this.getPaymentStatus(pr.status)}</td>
                  </tr>
                )
              }
            </tbody>
          </table>

        }
        onClose={onClose}
      />
    );
  }
}

export default PaymentComponent
