import {
  LOAD_PROPERTIES,
  LOAD_PROPERTIES_TYPES,
  LOAD_PROPERTIES_AREAS,
  LOAD_PROPERTIES_RENT_PRICES,
  LOAD_PROPERTIES_SELL_PRICES,
  LOADING_PROPERTIES
} from '../ActionTypes';

import {
  sortArray
} from '../../Utils/Utilities';

const initialState = {
  list: null,
  types: [],
  areas: [],
  rentPrices: [],
  sellPrices: [],
  params: {
    page: 1,
    size: 6,
  },
  currentPage: 1,
  loadFinish: false
};

export default function PropertiesReducer(state: Object = initialState, action: {type: string, payload:any}) {
  switch (action.type) {
    case LOAD_PROPERTIES:
      return Object.assign({}, state, {
        list: sortArray(action.payload.list, 'ID'),
        loadFinish: true,
        pages: action.payload.pages,
        params: action.payload.params
      });
    case LOAD_PROPERTIES_TYPES:
      return Object.assign({}, state, {
        types: sortArray(action.payload, 'name')
      });
    case LOAD_PROPERTIES_AREAS:
      return Object.assign({}, state, {
        areas: action.payload
      });
    case LOAD_PROPERTIES_RENT_PRICES:
      return Object.assign({}, state, {
        rentPrices: action.payload
      });
    case LOAD_PROPERTIES_SELL_PRICES:
      return Object.assign({}, state, {
        sellPrices: action.payload
      });
    case LOADING_PROPERTIES:
      return Object.assign({}, state, {
        loadFinish: false
      });

    default:
      return state;
  }
}
