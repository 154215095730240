import React, { Component } from 'react';
// import MainImage from '../../Assets/Images/DSC_3613.jpg';
import MainImage from '../../Assets/Images/groupPhoto.jpg';
import './AboutUs.scss';

class AboutUs extends Component<{}, {}> {
    render() {
        return (
            <div className="container">
                <div className="row p-5">
                    <div className="col-md-12 col-lg-6">
                        <img
                            className="about-us-image img-fluid mb-md-3"
                            src={MainImage}
                            alt="not found"
                        />
                    </div>
                    <div className="col-md-12 col-lg-6 mt-5 mt-lg-0">
                        <h2 className="text-primary font-weight-bold">
                            Sobre Ayurá
                        </h2>
                      <br/>
                        <span>
                            <h5>
                                <b>Trayectoria</b>
                            </h5>
                            <p className="text-ayu-size-sm text-justify pt-2">
                                ARRENDAMIENTOS AYURA S.A.S. Fue fundada el 19 de
                                octubre de 1.988 mediante escritura pública Nº
                                0004558 de la notaria 18 de Medellín, con
                                domicilio en el municipio de Envigado. Además
                                cuenta con el respaldo de la lonja de propiedad
                                raíz de Medellín y Antioquia, lonja de
                                Antioquia.
                            </p>
                            <h5>
                                <b>Misión</b>
                            </h5>
                            <p className="text-ayu-size-sm text-justify pt-2">
                                Para ARRENDAMIENTOS AYURA S.A.S. Lo más importante
                                es prestar a toda su clientela un servicio
                                excelente, con gran profesionalismo. Brindar una
                                imagen de solidez financiera y de cumplimiento
                                en las obligaciones y deberes, tanto para
                                arrendatarios como para propietarios.
                            </p>
                            <h5>
                                <b>Visión</b>
                            </h5>
                            <p className="text-ayu-size-sm text-justify pt-2">
                                Para el año 2.020 consolidarnos como una gran
                                empresa inmobiliaria en el sur del Valle de
                                Aburra, con un excelente servicio al cliente.
                                Igualmente establecer las estrategias necesarias
                                para penetrar en el mercado nacional e
                                internacional.
                            </p>
                        </span>
                    </div>
                </div>
            </div>
        );
    }
}

export default AboutUs;
