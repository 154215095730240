import React, {Component} from 'react';
import './FormComponent.scss';
import {toast} from '../../../Services/AlertService';

type Props = {
  logo: string,
  title: string,
  email: boolean,
  click: any
};
type State = {
  email: text,
  id: number,
  password: number
};

class FormComponent extends Component<Props, State> {
  state = {
    email: null,
    id: null,
    password: null
  };

  handleChange = (event: SyntheticEvent<HTMLButtonElement>) => {
    const {value, name} = event.currentTarget;
    this.setState({[name]: value});
  };
  
  handleSubmit = (event: SyntheticEvent<HTMLButtonElement>) => {
    event.preventDefault();

    if (this.props.email) {
      // entra aqui si es un form de correo
      const {email, password} = this.state;
      if((!email || !password) || (email.length === 0 || password === 0 || !email.includes("@"))){
        toast('Por favor llene los campos', 'error');
        return;
      }
      this.props.click(email, password);
    } else {
      // entra aqui si es un form de cedula
      const {id, password} = this.state;
      if((!id || !password) || (id.length === 0 || password === 0)){
          this.setState({error: true});
          toast('Por favor llene los campos', 'error');
          return;
      }
      this.props.click(id, password);
    }
  };
  
  render() {
    if (this.props.email) {
      return (
        <div className="card card-login">
          <div className="card-body">
            <div className="d-flex mb-3 ">
              <img
                width="33"
                src={this.props.logo}
                alt="..."
                className="mr-2"
              />
              <h3>{this.props.title}</h3>
            </div>

            <form onSubmit={this.handleSubmit}>
              <div className="form-group">
                <input
                  type="text"
                  name="email"
                  className="form-control"
                  aria-describedby="emailHelp"
                  placeholder="Correo Electrónico"
                  onChange={this.handleChange}
                />
              </div>
              <div className="form-group">
                <input
                  type="password"
                  name="password"
                  onChange={this.handleChange}
                  className="form-control"
                  placeholder="Contraseña"
                />
              </div>
              <button
                type="submit"
                className="btn btn-secondary mb-3">
                Entrar
              </button>
            </form>
          </div>
        </div>
      );
    } else {
      return(
        <div className="card card-login">
          <div className="card-body">
            <div className="d-flex mb-3 ">
              <img
                width="33"
                src={this.props.logo}
                alt="..."
                className="mr-2"
              />
              <h3>{this.props.title}</h3>
            </div>

            <form onSubmit={this.handleSubmit}>
              <div className="form-group">
                <input
                  type="text"
                  name="id"
                  className={`form-control ${this.state.error && !this.state.id ? 'border border-danger' : ''}`}
                  aria-describedby="emailHelp"
                  placeholder="Cédula o Correo Electrónico"
                  onChange={this.handleChange}
                />
              </div>
              <div className="form-group">
                <input
                  type="password"
                  name="password"
                  onChange={this.handleChange}
                  className={`form-control ${this.state.error && !this.state.password && 'border border-danger'}`}
                  placeholder="Contraseña"
                />
              </div>
              <button
                type="submit"
                className="btn btn-secondary mb-3">
                Entrar
              </button>
            </form>
          </div>
        </div>
      );
    }
  }
}

export default FormComponent;
