import React, {Component, Fragment} from 'react';
// import MainImage from '../../Assets/Images/servicioCartera1.jpg';
import MainImage2 from '../../Assets/Images/servicioCartera2.jpg';

class Collection extends Component <{}, {}> {
  render() {
    return (
      <Fragment>
        {/*<img className = "services-image img-fluid mb-5" src = {MainImage} alt = "not found"/>*/}
        {/*  <img className = "services-image img-fluid mb-5" src = {MainImage2} alt = "not found"/>*/}
        {/*<span className = "col-sm">*/}
        {/*  <p>La estrategia de Mercadeo:</p>*/}
        {/*  <ul>*/}
        {/*    <li className="text-justify">Es el Departamento que busca soluciones al recaudo efectivo de la cartera.</li>*/}
        {/*    <li className="text-justify">Satisfacemos los requerimientos de información exacta que garanticen el éxito en la administración de cartera de nuestros clientes fortaleciendo sus relaciones comerciales.</li>*/}
        {/*    <li className="text-justify">Contamos con una organización jurídica especializada en trámites de procesos de restitución de inmuebles arrendados y ejecutivos por el incumplimiento de los arrendatarios.</li>*/}
        {/*    <li className="text-justify">Realizamos el cobro prejurídico y jurídico de cánones de arrendamiento, cuotas de administración y servicios públicos cuando los arrendatarios dejan de cancelarlos.</li>*/}
        {/*    <li className="text-justify">Información permanente y actualizada del estado de cada proceso judicial que se encuentra en trámite.</li>*/}
        {/*  </ul>*/}
        {/*  <p>*/}
        {/*    Asesoría jurídica inmediata y permanente.*/}
        {/*  </p>*/}
        {/*</span>*/}
        <div className="container-fluid">
          <div className="row ">
            <div className="col-12  col-lg-6 ">
                        <span className="col-sm">
                          <p>La estrategia de Mercadeo:</p>
                          <ul>
                            <li className="text-justify">Es el Departamento que busca soluciones al recaudo efectivo de la cartera.</li>
                            <li className="text-justify">Satisfacemos los requerimientos de información exacta que garanticen el éxito en la administración de cartera de nuestros clientes fortaleciendo sus relaciones comerciales.</li>
                            <li className="text-justify">Contamos con una organización jurídica especializada en trámites de procesos de restitución de inmuebles arrendados y ejecutivos por el incumplimiento de los arrendatarios.</li>
                            <li className="text-justify">Realizamos el cobro prejurídico y jurídico de cánones de arrendamiento, cuotas de administración y servicios públicos cuando los arrendatarios dejan de cancelarlos.</li>
                            <li className="text-justify">Información permanente y actualizada del estado de cada proceso judicial que se encuentra en trámite.</li>
                          </ul>
                          <p>
                            Asesoría jurídica inmediata y permanente.
                          </p>
                        </span>
            </div>
            <div className="col-12  col-lg-6 align-content-center ">
              <img className="services-image img-fluid m-5 w-75" src={MainImage2} alt="not found"/>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default Collection;
