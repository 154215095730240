import React, {Component, Fragment} from 'react';
import Navbar from '../Shared/LoggedNavbarComponent';
import type {RouterHistory} from "react-router-dom";
import AdminDashboard from "./DashboardComponent/AdminDashboard";
import AuthService from "../../Services/AuthService";


type state = {
  mainLoad: boolean
}

type Props = {
  history: RouterHistory
};

class AdminMainComponent extends Component<Props, state>{

  logOut(){
    AuthService.removeLocalStorageItems();
    this.props.history.push({
      pathname: '/admin/login'
    });
  }


  loadRoot(){
    this.setState(previousState => {
      return {...previousState, mainLoad: true};
    });
  }

  componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS): void{

  }

  render(){
    return (
      <Fragment>
        <Navbar action = {this.logOut.bind(this)}/>

        <AdminDashboard />

        <div className="py-5"></div>
      </Fragment>
    );
  }
}

export default AdminMainComponent;
