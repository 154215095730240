import React, { Component, Fragment } from 'react';

class Map extends Component<{}, {}> {
    render() {
        return (
            <Fragment>
                <iframe
                    className="w-100"
                    title="map"
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d768.4190840142054!2d-75.58684617187481!3d6.1701074392044255!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8e46824d8ae32f0f%3A0xf000be5f2b59194!2sArrendamientos+Ayur%C3%A1!5e0!3m2!1sen!2sco!4v1547734407229"
                    height="222"
                    frameBorder="0"
                    style={{ border: 0 }}
                />
            </Fragment>
        );
    }
}

export default Map;
