import React, {Component} from 'react';
import Icon from '../../Assets/Images/pdf-icon.png';

class FileList extends Component<{},{}>{
  render(){
    return (
          <ul className = "list-unstyled p-3">
            <li className="py-3"><a href="https://sbxcloud.com/www/ayuraweb/Images/solicitud_persona_juridica.pdf" download target='_blank' rel="noopener noreferrer"> <img src = {Icon} height = '15px' alt = ""/> Formato de solicitud persona juridica.</a></li>
            <li className="py-3"><a href="https://sbxcloud.com/www/ayuraweb/Images/solicitud_persona_natural.pdf" download target='_blank' rel="noopener noreferrer"><img src = {Icon} height = '15px' alt = ""/> Formato de solicitud persona natural.</a></li>
            <li className="py-3"><a href="https://sbxcloud.com/www/ayuraweb/Images/requisitos.pdf" download target='_blank' rel="noopener noreferrer"><img src = {Icon} height = '15px' alt = ""/> Requisitos.</a></li>
            <li className="py-3"><a href="https://sbxcloud.com/www/ayuraweb/Images/formularioPersonaJuridica.pdf" download target='_blank' rel="noopener noreferrer"><img src = {Icon} height = '15px' alt = ""/> Formulario persona jurídica - Aseguradora.</a></li>
            <li className="py-3"><a href="https://sbxcloud.com/www/ayuraweb/Images/formularioPersonaNatural.pdf" download target='_blank' rel="noopener noreferrer"><img src = {Icon} height = '15px' alt = ""/> Formulario persona natural - Aseguradora.</a></li>
            <li className="py-3"><a href="https://sbxcloud.com/www/ayuraweb/Images/instruccionesPersonaNatural.pdf" download target='_blank' rel="noopener noreferrer"><img src = {Icon} height = '15px' alt = ""/> Instrucciones uso-persona natural.</a></li>
            <li className="py-3"><a href="https://sbxcloud.com/www/ayuraweb/Images/instruccionesPersonaJuridica.pdf" download target='_blank' rel="noopener noreferrer"><img src = {Icon} height = '15px' alt = ""/> Instrucciones uso-persona jurídica.</a></li>
            <li className="py-3"><a href="https://sbxcloud.com/www/ayuraweb/Images/solicitudAVALCREAR.pdf" download target='_blank' rel="noopener noreferrer"><img src = {Icon} height = '15px' alt = ""/> Solicitud AVALCREAR.</a></li>
            <li className="py-3"><a href="https://sbxcloud.com/www/ayuraweb/Images/solicitud_avalcrear_tomador.pdf" download target='_blank' rel="noopener noreferrer"><img src = {Icon} height = '15px' alt = ""/> Solicitud AVALCREAR Tomador.</a></li>
          </ul>
    );
  }
}

export default FileList;
