import React, {Component, Fragment} from "react"
import {Button} from "reactstrap";
import Navbar from "../Shared/LoggedNavbarComponent";
import AuthService from "../../Services/AuthService";
import RequestsService from "../../Services/RequestsService";
import {withRouter} from "react-router-dom";
import "./RepairDetailComponent.scss"
import RepairDetailItemComponent from "./RepairDeatilItemComponent";
import LoadingComponent from ".././Shared/Loadig/LoadingComponent"

class RepairDetailComponent extends Component {

    state = {
        repairId: 0,
        details: [],
        showLoading: true,
    }

    componentDidMount() {
        let {contract, id} = this.props.match.params;
        this.setState({ repairId: id })
        this.getRepairs(contract, parseInt(id))
    }

    logOut(){
        AuthService.removeLocalStorageItems();
        this.props.history.push({
            pathname: '/login_clients'
        });
    }

    getRepairs(contract, id) {
        RequestsService.getRepairs(contract).then(items => {
            this.setState({
                details: items.filter(item => (
                    item.Cons_Encabezado === id
                )),
                showLoading: false
            })
        }).catch(() => {
            this.setState({ showLoading: false });
        })
    }

    goBack() {
        this.props.history.goBack();
    }

    render() {
        let { showLoading } = this.state;

        return (
            <Fragment>
                <Navbar action = {this.logOut.bind(this)}/>

                <div className={"container repair-detail-content"}>
                    <div className="row">
                        <div className="col-12">
                            <div className="row mt-3">
                                <div className={"col-2 d-flex justify-content-center"}>
                                    <Button onClick={this.goBack.bind(this)} color={"secondary"}>
                                        <i className={"fa fa-arrow-left mr-2"}/>
                                        Regresar
                                    </Button>
                                </div>
                                <div className={"col-12 col-md-10"}>
                                    <h3>Detalle de la reparación #{this.state.repairId}</h3>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 d-flex justify-content-center">
                            <LoadingComponent show={showLoading}/>
                        </div>
                        {
                            this.state.details.map((detail) => (
                                <div className="col-12 p-4" key={detail.Cons_Web}>
                                    <RepairDetailItemComponent detail={detail}/>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </Fragment>
        );
    }
}

export default withRouter(RepairDetailComponent);