import {post, setToken} from '../NetworkLayer/Index';
import {URL} from '../Utils/Config';

export default class AuthService {

  static changeEmail(params) {
    return post(URL.user.emailChange, params);
  }

  static removeLocalStorageItems () {
    localStorage.removeItem('token');
    localStorage.removeItem('login');
    localStorage.removeItem('user_id');
    localStorage.removeItem('document');
  }

  static login(param: {
    login: number,
    password: number
  }): Promise<{
    success: boolean,
    token: string
  }> {
    this.removeLocalStorageItems();
    return post(URL.user.login, param).then((res: any) => {
      const {result, token, id, document, login} = res;
      const response = {success: false, token: null};

      if (result === "OK") {
        localStorage.setItem('token', token);
        localStorage.setItem('login', login);
        localStorage.setItem('user_id', id);
        if (document) {
          localStorage.setItem('document', document);
        }
        setToken(token);
        response.success = true;
        response.token = token;
        response.msg = 'Login Successful';
        delete response.error;
      }else{
        response.msg = res.msg;
      }
      return response;
    })
    .catch(error => {
      console.error(error);
    });
  }

  static getToken() {
    return localStorage.getItem('token');
  }
  static getUserId(){
    return localStorage.getItem('user_id');
  }
  static getLogin(){
    return localStorage.getItem('login');
  }
  static getDocument(){
    return localStorage.getItem('document');
  }
}
