import React, {Component} from 'react';
import MainImage from '../../Assets/Images/serviciosAvaluo.jpg';

class Appraise extends Component <{},{}>{
  render(){
    return (
        <div className="container-fluid">
          <div className="row">
            <div className="col-4">
              <img className = "services-image img-fluid mb-5" src = {MainImage} alt = "not found"/>
            </div>
            <div className="col-8">
              <p>Contamos con el respaldo de las Lonjas de Medellín y de Antioquia y la Lonja de Antioquia.</p>
            </div>
          </div>
        </div>
    );
  }
}

export default Appraise;
