import RequerimentsIcon from '../Assets/Images/requisitos@3x.png'
import PayOnlineIcon from '../Assets/Images/pagos-en-linea@3x.png'
import VenuesIcon from '../Assets/Images/sedes@3x.png'
import ContactUsIcon from '../Assets/Images/contactanos@3x.png'

export default [
  {
    image: RequerimentsIcon,
    title: 'Requisitos',
    description: 'Conoce los requisitos para venta y arrendamiento de inmuebles',
    path: '/requisitos',
    id:1
  },
  {
    image: PayOnlineIcon,
    title: 'Registre su pago',
    description: 'Paga tu canon de arrendamiento de forma fácil y segura',
    path: '/pagos',
    id: 2
  },
  {
    image: VenuesIcon,
    title: 'Políticas de seguridad',
    description: 'Conoce nuestras políticas de tratamiento de datos',
    path: 'https://sbxcloud.com/www/ayuraweb/Images/AUTORIZACION TRATAMIENTO DE DATOS-arrendatario.pdf',
    id: 3
  },
  {
    image: ContactUsIcon,
    title: 'Contáctanos',
    description: 'Conoce los requisitos para venta y arrendamiento de inmuebles',
    path: '/contactanos',
    id: 4
  }
];
