import React, {Component} from 'react';
import Dropzone from "react-dropzone";
import {toast} from '../../Services/AlertService';
import SharedService from "../../Services/SharedService";

type state = {
    info: array<any>
}

class PaymentComponent extends Component <{}, state>{

    state = {
        info: [
            {
                type: 'text',
                placeHolder: '*Nombre',
                name: 'name'
            },
            {
                type: 'email',
                placeHolder: '*Correo Electrónico',
                name: 'email'
            },
            {
                type: 'number',
                placeHolder: '*Teléfono',
                name: 'phone'
            },
            {
                type: 'number',
                placeHolder: 'Celular',
                name: 'cellphone'
            },
        ],
        files: [],
        sum: [this.randomNum(10), this.randomNum(10)]
    };


    randomNum(max){
        return Math.floor(Math.random() * Math.floor(max - 1)) + 1;
    }

    handleChange = event => {
        const {value, name} = event.target;
        this.setState({[name]: value});
    };

    onDrop(files){
        this.setState({files});
    }

    uploadFile(){
        this.props.uploadFile(this.state.files[0],);
        this.setState({...this.state, files: []});
    }

    onCancel(){
        this.setState({
            files: []
        });
    }

    // getBase64(file){
    //     return new Promise((resolve, reject) => {
    //         const reader = new FileReader();
    //         reader.readAsDataURL(file);
    //         reader.onload = () => resolve(reader.result);
    //         reader.onerror = error => reject(error);
    //     });
    // }

    componentDidMount(){
    }

    sendPaymentInfo(){
        const {name, email, phone, cellphone, total, sum} = this.state;
        if(parseInt(total) === sum.reduce((a, b) => a + b, 0)){
            if(!name || !email || !phone){
                toast('Ingrese los campos requeridos.', 'error');
            } else{
                if(!email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)){
                    toast('Ingrese un correo electrónico válido', 'error');
                } else{
                    if(!this.state.files.length){
                        toast('Por favor adjunte un archivo.', 'error');
                        return
                    }
                        SharedService.sendPaymentDocument(this.state.files[0], {name, phone, cellphone, email})
                        .then(res => {
                            toast('Información enviada correctamente', 'info')
                        }).catch(err => {
                            console.error(err);
                            toast('Un error ha ocurrido, intentelo más tarde', 'error')
                        })
                }
            }

        } else{
            toast('Realice la suma de verificación.', 'error')
        }
    }


    render(){
        const files = this.state.files.map(file => (
            <li key = {file.name}>
                {file.name} - {file.size} bytes
            </li>
        ));
        const {info} = this.state;
        return (
            <div className = "container bg-white py-3">
                <div className = "row mt-3 mb-5">
                    <div className = "col-2 d-flex justify-content-center">
                        <button className = "btn btn-secondary px-3"
                                onClick = {this.props.history.goBack}>
                            <i className = "fa fa-arrow-left mr-2"/>Regresar
                        </button>
                    </div>
                    <div className = "col-12 col-md-10">
                        <h2 className = "text-primary text-left text-ayu-bold">
                            Registre su pago
                        </h2>
                    </div>
                    <div className = "col-12 mt-2 d-flex justify-content-center">
                        <span>A través de este formulario usted puede adjuntarnos su recibo de pago</span>
                    </div>
                </div>
                <div className = "row p-4">
                    <div className = "col-12">
                        <div className = "row">
                            {info.map(i => (
                                <div className = "col-6 col-md-3 form-check mb-2" key = {i.name}>
                                    <label className = "text-ayu-bold">
                                        {i.placeHolder}
                                    </label>
                                    <input
                                        className = "form-control mb-3"
                                        type = {i.type}
                                        placeholder = {i.placeHolder}
                                        name = {i.name}
                                        value = {this.state[i.name]}
                                        onChange = {this.handleChange}
                                    />
                                </div>
                            ))}
                            < Dropzone onDrop = {this.onDrop.bind(this)}
                                       onFileDialogCancel = {this.onCancel.bind(this)}>
                                {({getRootProps, getInputProps}) => (
                                    <div {...getRootProps()}
                                         className = "col-12 col-md-6 doc-uploader mb-3">
                                        <input  {...getInputProps()} />
                                        {this.state.files.length ?
                                            files :
                                            (<span>Arrastre recibo de pago aquí, o presione click para seleccionarlo</span>)
                                        }
                                    </div>
                                )}
                            </Dropzone>
                            <div className = "col-6 col-md-3 form-check mb-2">
                                <label className = "text-ayu-bold">
                                    Cuanto es {this.state.sum[0]} + {this.state.sum[1]}?
                                </label>
                                <input
                                    className = "form-control mb-3"
                                    type = 'number'
                                    name = 'total'
                                    placeholder = 'Responda para continuar'
                                    value = {this.state.total}
                                    onChange = {this.handleChange}
                                />
                            </div>
                        </div>
                        <div className = "row">
                            <div className = "d-flex justify-content-end">
                                <button className = "btn btn-secondary send-button"
                                        disabled = {!this.state.total}
                                        onClick = {() => this.sendPaymentInfo()}>
                                    Enviar
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default PaymentComponent;
