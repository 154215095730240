export default [
    {
        title: 'Corporativos',
        description: 'Son aquellos elementos que hacen posible tener una buena cultura organizacional',
        id:1
    },
    {
        title: 'Responsabilidad',
        description: 'Es cumplir de manera correcta con nuestras obligaciones mostrando así nuestro compromiso con los clientes',
        id: 2
    },
    {
        title: 'Cumplimiento',
        description: 'Buscamos en todo momento cumplir de manera correcta y en el menor tiempo posible con nuestros deberes',
        id: 3
    },
    {
        title: 'Transparencia',
        description: 'Es mostrar a nuestros clientes claramente la labor que realizamos',
        id: 4
    },
    {
        title: 'Organización',
        description: 'Es actuar de manera conjunta con una serie de objetivos que permiten alcanzar las metas',
        id: 5
    },
    {
        title: 'Calidad en el servicio',
        description: 'Es buscar en todo momento atender a nuestros clientes con la mejor disposición',
        id: 6
    }
];
