import * as Action from '../ActionTypes';
import {sortArray} from '../../Utils/Utilities';

const initialState = {
    contracts: [],
    contractTotalPages: 0,
    documents: [],
    comments: [],
    payments: [],
    pages: 0,
    photos: [],
    current_request: {},
    loadingDocuments: true,
};

export default function InformationReducer(
    state: any = initialState,
    {
        type,
        payload,
    }: {
        type: string,
        payload: any,
    }
) {
    switch (type) {
        case Action.LOAD_PAYMENTS:
            return Object.assign({}, state, {
                payments: sortArray(payload, 'due_date'),
            });

        case Action.LOAD_CONTRACTS:
            return Object.assign({}, state, {
                contracts: sortArray(payload.contracts, 'label'),
                contractTotalPages: payload.total_pages,
            });

        case Action.SHOW_LOADING:
            return {...state, loadingDocuments: true};

        case Action.LOAD_DOCUMENTS:
            return {
                ...state,
                loadingDocuments: false,
                documents: payload.items,
                pages: payload.total_pages,
                photos: payload.items.reduce((result, doc) => {
                    if (doc.icon_type === 'Document' && doc.file_name.indexOf('JPG') !== -1) {
                        result.push(doc.content_path);
                    }
                    return result;
                }, []),
            };

        case Action.LOAD_REQUEST_INFO:
            return Object.assign({}, state, {
                information: payload,
                current_request: payload.length ? payload[0] : {},
            });

        case Action.CHANGE_CURRENT_REQUEST:
            return Object.assign({}, state, {
                current_request: state.information[payload],
            });

        case Action.LOAD_REQUEST_DOCUMENTS:
            return Object.assign({}, state, {
                documents: payload.items,
                pages: payload.totalPages,
            });

        case Action.LOAD_REQUEST_COMMENTS:
            return Object.assign({}, state, {
                comments: payload,
            });

        default:
            return state;
    }
}
