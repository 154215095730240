import React, {Component} from 'react';
import '../../Styles/_button.scss';
import './Services.scss';
import Leasing from './LeasingComponent';
import Sales from './SalesComponent';
import RequestReview from './RequestReviewComponent';
import Collection from './CollectionComponent';
import Repairs from './RepairsComponent';
import CustomerService from './CustomerServiceComponent';
import PublicServices from './PublicServicesComponent';
import TaxPayments from './TaxPaymentsComponent';
import Warranty from './WarrantyComponent';
import Appraise from './AppraiseComponent';
import Relocation from './RelocationComponent';
import SystemAssist from './SystemAssistComponent';
import Filing from './FilingComponent';

class MenuComponent extends Component <{},{}>{

  constructor(props){
    super(props);
    this.state = {list:[
        {id:1, label: 'Arrendamientos', component: <Leasing/>, selected:true},
        {id:2, label: 'Ventas', component: <Sales/>, selected:false},
        {id:3, label: 'Estudio de solicitudes', component: <RequestReview/>, selected:false},
        {id:4, label: 'Cartera', component: <Collection/>, selected:false},
        {id:5, label: 'Reparaciones', component: <Repairs/>, selected:false},
        {id:6, label: 'Servicio al cliente', component: <CustomerService/>, selected:false},
        {id:7, label: 'Servicios públicos', component: <PublicServices/>, selected:false},
        {id:8, label: 'Pago prediales', component: <TaxPayments/>, selected:false},
        {id:9, label: 'Garantía y cubrimientos', component: <Warranty/>, selected:false},
        {id:10, label: 'Avalúos', component: <Appraise/>, selected:false},
        {id:11, label: 'Reubicación', component: <Relocation/>, selected:false},
        {id:12, label: 'Auxiliar de Sistemas', component: <SystemAssist/>, selected:false},
        {id:13, label: 'Archivo Digital', component: <Filing/>, selected:false}
        ]}
  }
  click(item){
    const tmp = this.state.list.map(i => {i.selected = i.id === item.id; return i;});
    this.setState({list: tmp});
    this.props.toggleComp(item.component);
  }
  render(){
    return (
        <div className = "container m-0 services-menu">
          <ul className = "text-primary py-5">
            {this.state.list.map(item => (<li key={item.id} className={item.selected ? 'active-list-item' : ''} onClick = {() => this.click(item)}>{item.label}</li>))}
          </ul>
        </div>
    );
  }
}

export default MenuComponent;
