// @flow
import iziToast from 'izitoast';

export function toast(message: string, type ? : string = 'success'): void {

    iziToast[type]({
        message: message,
        position: 'bottomLeft', // bottomRight, bottomLeft, topRight, topLeft, topCenter, bottomCenter, center
        displayMode: 2,
        closeOnClick: true
    })
}