import ContactUs from '../Components/ContactUs/ContactUsComponent';
import AboutUs from '../Components/AboutUs/AboutUsComponent';
import PropertyDetailComponent from '../Components/PropertyDetail/PropertyDetailComponent';
import Services from '../Components/Services/ServicesComponent';
import LoginClientComponent from '../Components/LoginClient/LoginClientComponent';
import Requirements from '../Components/Requirements/RequirementsComponent';
import Search from '../Components/Search/SearchComponent';
import DirectoryComponent from '../Components/Directory/DirectoryComponent';
import RegisterComponent from '../Components/Register/RegisterComponent';
import LoginRequestComponent from '../Components/LoginRequest/LoginRequestComponent';
import OurValuesComponent from '../Components/OurValues/OurValuesComponent'
import PaymentComponent from '../Components/Payment/PaymentComponent';
import RepairDetailComponent from "../Components/Repair/RepairDetailComponent";
// import LoginAdminComponent from '../Components/LoginAdmin/LoginAdminComponent';
// import AdminMainComponent from '../Components/Admin/AdminMainComponent';

const Routes = [
  {
    path: '/contactanos',
    component: ContactUs,
    id: 1
  },
  {
    path: '/sobre_ayura',
    component: AboutUs,
    id: 2
  },
  {
    path: '/property',
    component: PropertyDetailComponent,
    id: 3
  },
  {
    path: '/servicios',
    component: Services,
    id: 4
  },
  {
    path: '/login_clients',
    component: LoginClientComponent,
    id: 5
  },
  {
    path: '/requisitos',
    component: Requirements,
    id: 6
  },
  {
    path: '/buscar',
    component: Search,
    id: 7
  },
  {
    path: '/inquilino',
    component: DirectoryComponent,
    id: 8
  },
  {
    path: '/contratos',
    component: DirectoryComponent,
    id: 9
  },
  {
    path: '/registrar_inmueble',
    component: RegisterComponent,
    id: 10
  }, {
    path: '/consultar_solicitud',
    component: LoginRequestComponent,
    id: 11
  }, {
    path: '/pagos',
    component: PaymentComponent,
    id: 12
  },
  {
    path: '/valores',
    component: OurValuesComponent,
    id: 13
  }/*, {
    path: '/admin/login',
    component: LoginAdminComponent,
    id: 14
  }, {
    path: '/admin/dashboard',
    component: AdminMainComponent,
    id: 15
  }*/
  ,
  {
    path: '/reparaciones/detalle/:contract/:id',
    component: RepairDetailComponent,
    id: 16
  }
];
export default Routes;
