import React, {Component} from 'react';
import MainImage from '../../Assets/Images/serviciosGarantia.jpg';

class Warranty extends Component <{},{}>{
  render(){
    return (
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 col-lg-8">
              <ul>
                <li className="text-justify">Cancelamos oportunamente el canon de arrendamiento pague o no el arrendatario, esto es solidez y respaldo.</li>
                <li className="text-justify">Damos respuesta inmediata al estudio de la solicitud (ocho horas después de ser radicada).</li>
                <li className="text-justify">Elaboramos los contratos de arrendamiento ajustados a la normatividad legal vigente.</li>
                <li className="text-justify">Brindamos agilidad en la atención a requerimientos e inquietudes de nuestros propietarios, sin necesidad de realizar trámite alguno con otra entidad.</li>
                <li className="text-justify">Realizamos toma fotográfica al propietario, arrendatario y coarrendatarios.</li>
                <li className="text-justify">Damos las referencias comerciales a arrendatarios y propietarios con la toma fotográfica para evitar futuros fraudes.</li>
              </ul>
            </div>
            <div className="col-12 col-lg-4">
              <img className = "services-image img-fluid mb-5" src = {MainImage} alt = "not found"/>
            </div>
          </div>
        </div>
    );
  }
}

export default Warranty;
