import React, {Component, Fragment} from 'react';
import {NavLink, Spinner} from 'reactstrap';
import {format} from 'date-fns';
import {NavLink as RRNavLink} from "react-router-dom";

class RequestInfoComponent extends Component<{}, {}>{

  render(){
    const {info, request, changeRequest} = this.props;
    if(!info){
      return (
          <div className = "container py-5">
            <div className = "row py-5 d-flex justify-content-center">
              <Spinner size = "lg" color = "primary"/>
            </div>
          </div>
      );
    } else{
      request.created = new Date(request.created);
      return (
          <div className = "container">
            <Fragment>
              <div className = "row py-5">
                <div className = "col-12">
                  <h2 className = "text-primary text-center text-ayu-bold">
                    Información de la solicitud # {request.id}
                  </h2>
                </div>
              </div>
              <div className = "row">
                <div className = "container">
                  <div className = "row d-flex justify-content-center">
                    <div className = "col-md-6">
                      <div className = "container">
                        <div className = "row text-center">
                          <div className = "container">
                            <div className = "row info-table p-1 info-table-header">
                              <div className = "col-6 p-1">Solicitud</div>
                              <div className = "col-6 ">Estado</div>
                            </div>
                            {info.map((i, index) =>
                                <div className = {`row info-table info-table-item clickable ${i.id === request.id ? "item-active":""}`} key={i.id}
                                onClick={()=> changeRequest(index)}>
                                  <div className = "col-6 ">{i.id}</div>
                                  <div className = "col-6 ">{i.status}</div>
                                </div>
                            )}

                          </div>
                        </div>
                      </div>
                    </div>
                    <div className = "col-md-6 table-responsive">
                      <table className = "table table-sm table-striped table-bordered">
                        <tbody>
                        <tr>
                          <th scope = "row">Radicado:</th>
                          <td># {request.id}</td>
                        </tr>
                        <tr>
                          <th scope = "row">Cons. Propiedad:</th>
                          <td>
                            <NavLink className = "d-flex p-0" to = {`/property?id=${request.property_id}`}
                                     target = '_blank' rel = "noopener noreferrer"
                                     activeClassName = "active" tag = {RRNavLink}>
                              # {request.property_id}
                            </NavLink>
                          </td>
                        </tr>
                        <tr>
                          <th scope = "row">Nombre</th>
                          <td>{request.contact_name}</td>
                        </tr>
                        <tr>
                          <th scope = "row">Creación:</th>
                          <td>{format(request.created, 'DD/MM/YYYY')}</td>
                        </tr>
                        <tr>
                          <th scope = "row">Estado:</th>
                          <td>{request.status}</td>
                        </tr>
                        <tr>
                          <th scope = "row">Último Mensaje:</th>
                          <td>{this.props.comments.length > 0 && this.props.comments[0].comment}</td>
                        </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </Fragment>
          </div>
      );
    }
  }
}


export default RequestInfoComponent;
