import React, {Component} from "react";
import Dropzone from "react-dropzone";
import "./RepairDescriptionComponent.scss"

class RepairDescriptionComponent extends Component {

    thumbsContainer = {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginTop: 20
    };

    thumbInner = {
        position: "relative",
        display: 'flex',
        minWidth: 0,
        overflow: 'hidden'
    };

    img = {
        display: 'block',
        width: '100%',
        // height: '100%'
    };

    cancel = {
        fontSize: "20px",
        fontWeight: 900,
        color: "red",
        display: "flex",
    };

    cancelContainer = {
        cursor: "pointer",
        position: "absolute",
        right: "0",
        border: "2px solid red",
        width: "30px",
        height: "30px",
        borderRadius: "20px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        background: "white"
    }

    PLUMBING_CODE = 3;
    LOCKSMITH_CODE = 4;

    onDrop(files) {
        files = files.map(file => Object.assign(file, {
            preview: URL.createObjectURL(file)
        }));
        const newFiles = this.props.description.files;
        newFiles.push(files[0]);
        this.props.onChange({...this.props.description, files: newFiles}, this.props.index)
    }

    handleChange(event) {
        const {value, name} = event.target;
        this.props.onChange({...this.props.description, [name]: value}, this.props.index)
    }

    deleteImage(index) {
        const files = this.props.description.files;
        files.splice(index, 1);
        this.props.onChange({...this.props.description, files}, this.props.index)
    }

    onCancel() {
        this.props.onChange({...this.props.description, files: []}, this.props.index)
    }

    removeDescription() {
        this.props.removeDescription(this.props.index)
    }

    render() {

        const files = this.props.description.files.map(file => (
            <li key={file.name}>
                {file.name} - {file.size} bytes
            </li>
        ));

        const thumbs = this.props.description.files.map((file, index) => (
            <div style={this.thumb} key={file.name}>
                <div style={this.thumbInner}>
                    <div
                        style={{...this.cancelContainer, marginTop: index > 0 ? "16px" : "0"}}
                        onClick={() => this.deleteImage(index)}>
                        <i className={"fa fa-times"} style={{
                            color: "red",
                            display: "flex",
                            justifyContent: "center"
                        }}/>
                    </div>
                    <img
                        src={file.preview}
                        style={{...this.img, marginTop: index > 0 ? "16px" : "0"}}
                        alt=""
                    />
                </div>
            </div>
        ));

        const getRepairDescription = () => {
            if (this.props.description.repairCode !== -1) {
                const repairCode = this.props.repairCodes.find((rc) => (
                    parseInt(rc.code) === parseInt(this.props.description.repairCode))
                )

                return repairCode.name
            }
            return "";
        }

        const getText = (input) => {
            const selectedCode = parseInt(this.props.description.repairCode);
            if (selectedCode === this.PLUMBING_CODE || selectedCode === this.LOCKSMITH_CODE) {
                return input + " de ";
            }
            return input + " ";
        }

        return (
            <div className={"col-12"}>
                <div className="row">
                    {
                        this.props.index > 0 &&
                        <div className="col-12">
                            <button
                                className={"btn btn-outline-danger"}
                                style={{
                                    cursor: "pointer",
                                    height: "30px",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    marginBottom: "2px"
                                }}
                                onClick={this.removeDescription.bind(this)}>
                                <i className={"fa fa-trash"}/>
                                {' '}&nbsp;Quitar daño
                            </button>
                        </div>
                    }
                    <div className={"col-12 col-md-3"}>
                        <div className="form-group">
                            <label htmlFor="">Tipo de Reparación</label>
                            <select
                                name="repairCode"
                                value={this.props.description.repairCode}
                                onChange={this.handleChange.bind(this)} className={"form-control"}
                            >
                                <option value={-1} disabled>Seleccione una opción</option>
                                {
                                    this.props.repairCodes.map(repairCode => (
                                        <option
                                            key={`repair_code${repairCode.code}`}
                                            value={repairCode.code}>
                                            {repairCode.name}
                                        </option>
                                    ))
                                }
                            </select>
                        </div>
                    </div>
                    <div className="col-12 col-md-4">
                        <div className="form-group">
                            <label className={"repair-description-label"} htmlFor="">{getText("Descripción de su daño")}
                                <b>{getRepairDescription()}</b></label>
                            <textarea name="description"
                                      onChange={this.handleChange.bind(this)}
                                      style={{resize: 'none'}}
                                      className="form-control mb-3"
                                      value={this.props.description.description}
                                      placeholder="Escriba una descripción del daño" rows="3"/>
                        </div>
                    </div>
                    <div className="col-12 col-md-5">
                        <div className="form-group">
                            <label className={"repair-description-label"}
                                   htmlFor="">{getText("Adjuntar fotos de su daño")}
                                <b>{getRepairDescription()}</b></label>
                            <Dropzone onDrop={this.onDrop.bind(this)}
                                      onFileDialogCancel={this.onCancel.bind(this)}
                                      accept={"image/png, image/gif, image/jpeg"}
                            >
                                {
                                    ({getRootProps, getInputProps}) => (
                                        <div {...getRootProps()}
                                             className="doc-uploader w-100 mb-3">
                                            <input  {...getInputProps()} />
                                            {
                                                files.length
                                                    ? <ul>{files.map(file => file)}</ul>
                                                    :
                                                    <span>Arrastre aquí evidencias o presione click para buscarlas en su computador</span>
                                            }
                                        </div>
                                    )
                                }
                            </Dropzone>
                            <aside style={this.thumbsContainer}>
                                {thumbs}
                            </aside>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default RepairDescriptionComponent;