import React, {Component} from 'react';
import {toast} from "../../../Services/AlertService";
import RequestsService from "../../../Services/RequestsService";
import RepairDescriptionComponent from "./RepairDescriptionComponent";
import LoadingComponent from "../../Shared/Loadig/LoadingComponent";
import ModalComponent from "../../Shared/ModalComponent";

class RepairComponent extends Component {
    componentDidMount() {
        this.setState({name: this.props.tenantName})
        RequestsService.getRepairCodes().then(items => {
            this.setState({repairCodes: items})
        })
    }

    state = {
        name: "",
        info: [
            {
                type: 'email',
                placeHolder: '*Correo Electrónico',
                name: 'email'
            },
            {
                type: 'number',
                placeHolder: '*Teléfono',
                name: 'phone'
            },
            {
                type: 'number',
                placeHolder: 'Celular',
                name: 'cellphone'
            },
            {
                type: 'text',
                placeHolder: 'Horario de disponibilidad',
                name: 'availability'
            },
        ],
        files: [],
        sum: [this.randomNum(10), this.randomNum(10)],
        descriptions: [
            {
                description: '',
                repairCode: -1,
                files: []
            }
        ],
        repairCodes: [],
        isModalOpen: false,
        showLoading: false,
    };

    thumb = {
        display: 'inline-flex',
        borderRadius: 2,
        border: '1px solid #eaeaea',
        marginBottom: 8,
        marginRight: 8,
        width: 100,
        height: 100,
        padding: 4,
        boxSizing: 'border-box',
        position: "relative",
        listStyle: "none",
        float: "left"
    };

    componentDidUpdate() {
        if(this.state.descriptions.length !== 1){
            window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' });
        }
    }

    getBase64(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });
    }

    handleChange = event => {
        const {value, name} = event.target;
        this.setState({[name]: value});
    };

    randomNum(max) {
        return Math.floor(Math.random() * Math.floor(max - 1)) + 1;
    }

    isNumber = (val) => (/^\d+$/.test(val));

    isEmpty = (val) => (String(val).trim().length === 0);

    onValidateForm = () => {
        const {total, sum, name, email, phone, nameOfWhoLive, phoneOfWhoLive} = this.state;

        if (parseInt(total) === sum.reduce((a, b) => a + b, 0)) {
            if (this.isEmpty(name) || this.isEmpty(email) || this.isEmpty(phone) || this.isEmpty(nameOfWhoLive) || this.isEmpty(phoneOfWhoLive)) {
                toast('Ingrese los campos requeridos.', 'error');
            } else if (!this.isNumber(phone)) {
                toast('Por favor verifique el número de teléfono.', 'error');
            } else if (!this.isNumber(phoneOfWhoLive)) {
                toast('Por favor verifique el número de teléfono de quien habita el inmueble.', 'error');
            } else if (!email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) {
                toast('Ingrese un correo electrónico válido', 'error');
            } else {

                let withErrors = false;

                let repairCodesSelected = this.state.descriptions.map(d => d.repairCode)
                let repairCodesDuplicated = [...new Set(repairCodesSelected)];

                if (repairCodesSelected.length !== repairCodesDuplicated.length) {
                    toast(`Uno o más códigos de reparación han sido seleccionados previamente`, 'error');
                    withErrors = true;
                }

                for (let [index, description] of this.state.descriptions.entries()) {
                    if (!description.files.length) {
                        toast(`Por favor adjunte los archivos en la descripción #${index + 1}.`, 'error');
                        withErrors = true;
                    }

                    if (description.repairCode === -1) {
                        toast(`Por favor seleccione un código de reparación valido en la descripción #${index + 1}.`, 'error');
                        withErrors = true;
                    }

                    if (description.description.replace(/\s/g, "") === "") {
                        toast(`Por favor ingrese la descripción #${index + 1}.`, 'error');
                        withErrors = true;
                    }
                }

                if (withErrors) return;

                this.setState({isModalOpen: true});

            }
        } else {
            toast('Realice la suma de verificación.', 'error')
        }
    }

    onSubmitForm = () => {
        this.setState({showLoading: true});

        const {name, email, phone, cellphone, availability, nameOfWhoLive, phoneOfWhoLive} = this.state;

        RequestsService.sendRepairRequest(this.state.descriptions, {
            name,
            contract: this.props.contract,
            email,
            phone,
            cellphone,
            availability,
            nameOfWhoLive,
            phoneOfWhoLive
        }).then(res => {
            if (res.success) {
                this.props.onCreatedRepair()
                return toast(res.res.msg, 'success')
            } else {
                toast('Un error ha ocurrido, intentelo más tarde', 'error')
            }
        }).catch(err => {
            console.error(err);

            this.setState({showLoading: false});
            toast('Un error ha ocurrido, intentelo más tarde', 'error')
        });
    }

    onChangeDescription(description, index) {
        let newDescription = [...this.state.descriptions]
        newDescription[index] = description;
        this.setState({descriptions: newDescription});
    }

    addNewDescription() {
        let newDescription = [...this.state.descriptions];
        newDescription.push({description: '', files: [], repairCode: -1})
        this.setState({descriptions: newDescription});
    }

    removeDescription(index) {
        let newDescriptions = [...this.state.descriptions]
        newDescriptions.splice(index, 1);
        this.setState({descriptions: newDescriptions});
    }

    render() {
        return (
            <div className="container bg-white py-3">
                <div className="row mt-3 mb-5">
                    <div className="col-2 d-flex justify-content-center">
                        <button className="btn btn-secondary px-3"
                                onClick={this.props.cancelRepair}>
                            <i className="fa fa-arrow-left mr-2"/>Regresar
                        </button>
                    </div>
                    <div className="col-12 col-md-10">
                        <h2 className="text-primary text-left text-ayu-bold">
                            Mantenimiento de inmueble
                        </h2>
                    </div>
                </div>
                <div className="row p-4">
                    <div className="col-12">
                        <div className="row">
                            <div className="col-12 col-md-6 form-check mb-2">
                                <label className="text-ayu-bold">
                                    *Nombre
                                </label>
                                <input
                                    className="form-control mb-3"
                                    type='text'
                                    name='name'
                                    value={this.state.name}
                                    onChange={this.handleChange}
                                />
                            </div>
                            <div className="col-12 col-md-6 form-check mb-2">
                                <label className="text-ayu-bold">
                                    Contrato
                                </label>
                                <input
                                    className="form-control mb-3"
                                    type='text'
                                    name='contract'
                                    value={this.props.contract}
                                    disabled={true}
                                />
                            </div>
                            {this.state.info.map(i => (
                                <div className="col-6 col-md-3 form-check mb-2" key={i.name}>
                                    <label className="text-ayu-bold">
                                        {i.placeHolder}
                                    </label>
                                    <input
                                        className="form-control mb-3"
                                        type={i.type}
                                        placeholder={i.placeHolder}
                                        name={i.name}
                                        onChange={this.handleChange}
                                    />
                                </div>
                            ))}
                            <div className="col-12 col-md-6 form-check mb-2">
                                <label className="text-ayu-bold">
                                    *Nombre quien habita el inmueble
                                </label>
                                <input
                                    className="form-control mb-3"
                                    type='text'
                                    name='nameOfWhoLive'
                                    placeholder={"*Nombre quien habita el inmueble"}
                                    onChange={this.handleChange}
                                />
                            </div>
                            <div className="col-12 col-md-6 form-check mb-2">
                                <label className="text-ayu-bold">
                                    *Teléfono quien habita el inmueble
                                </label>
                                <input
                                    className="form-control mb-3"
                                    type='number'
                                    name='phoneOfWhoLive'
                                    placeholder={"*Teléfono quien habita el inmueble"}
                                    onChange={this.handleChange}
                                />
                            </div>
                        </div>
                        <div className={"row repair-thumbs"}>
                            {
                                this.state.descriptions.map((description, index) => (
                                    <RepairDescriptionComponent
                                        key={`description_${index}`}
                                        description={description}
                                        index={index}
                                        onChange={this.onChangeDescription.bind(this)}
                                        removeDescription={this.removeDescription.bind(this)}
                                        repairCodes={this.state.repairCodes}
                                    />
                                ))
                            }
                        </div>
                        <div className={"row"}>
                            <div className="col-12">
                                <button className={"btn btn-danger"}
                                        onClick={this.addNewDescription.bind(this)}>Reportar otro daño
                                </button>
                                <br/>
                                <br/>
                            </div>
                            <div className="col-6 col-md-3 form-check mb-2">
                                <label className="text-ayu-bold">
                                    Cuanto es {this.state.sum[0]} + {this.state.sum[1]}?
                                </label>
                                <input
                                    className="form-control mb-3"
                                    type='number'
                                    name='total'
                                    min={0}
                                    placeholder='Responda para continuar'
                                    onChange={this.handleChange}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <button className="btn btn-secondary send-button"
                                        onClick={this.onValidateForm.bind(this)}
                                >
                                    <div className="row">
                                        <div
                                            style={{
                                                marginLeft: "6px",
                                                width: this.state.showLoading ? "calc(100% - 60px)" : "100%"
                                            }}
                                            className={"text-center"}>
                                            Enviar
                                        </div>
                                    </div>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                {this.state.isModalOpen && <ModalComponent size="md" header="Finalizar" onClose={() => this.setState({isModalOpen: false})}
                   body={
                       <div className="d-flex flex-column align-items-center w-100">
                           ¿Está seguro?
                       </div>
                   }
                   footer={
                       <div className="mx-auto">
                           <div className="d-flex align-items-center justify-content-evenly">
                               <button className="btn btn-secondary mr-2"
                                       onClick={this.onSubmitForm.bind(this)}
                               >
                                   {
                                       this.state.showLoading &&
                                       <div style={{marginTop: "-3px", marginLeft: "6px"}}>
                                           <LoadingComponent show={this.state.showLoading}/>
                                       </div>
                                   }
                                   <div className={"text-center"}
                                        style={{
                                            marginLeft: "6px",
                                            width: this.state.showLoading ? "calc(100% - 60px)" : "100%"
                                        }}>
                                       Enviar
                                   </div>
                               </button>
                               <button className="btn btn-danger"
                                       onClick={() => {
                                           this.setState({isModalOpen: false});
                                           toast("Por favor rellenar la información del daño");
                                           this.addNewDescription();
                                       }}
                               >
                                   <div className={"text-center"}>
                                       Reportar más daños
                                   </div>
                               </button>
                           </div>
                       </div>
                   }
                />}
            </div>
        );
    }
}

export default RepairComponent;
