import React, {Component, Fragment} from "react";
import PaymentService from "../../../Services/PaymentService";
import {convertToFullDate, formatPrice} from "../../../Utils/Utilities";
import AuthService from "../../../Services/AuthService";
import ModalComponent from "../../Shared/ModalComponent";
import {Alert} from "reactstrap";
import {toast} from "../../../Services/AlertService";
import PaymentInfoModal from "./PaymentInfoModal";

class PaymentComponent extends Component {
    state = {
        sum: [this.randomNum(10), this.randomNum(10)],
        email: "",
        password: "",
        loading: false,
        modalOpen: false,
    };

    randomNum(max) {
        return Math.floor(Math.random() * Math.floor(max - 1)) + 1;
    }

    handleChange = (event) => {
        let {value, name} = event.target;
        this.setState({[name]: value});
    };

    changeEmail = () => {
        const params = {
            login: this.state.email,
            password: this.state.password,
        };
        this.setState({loading: true});
        AuthService.changeEmail(params)
            .then((res) => {
                console.log(res);
                if (res && res.result === "OK") {
                    localStorage.setItem("login", params.login);
                    localStorage.setItem("document", res.document);
                    toast("Ingreso de email exitoso.", "success");
                    this.setState({modalOpen: false});
                } else {
                    toast(res.msg, "error");
                }
                this.setState({loading: false});
            })
            .catch((err) => {
                console.log(err.message);
                this.setState({loading: false});
                toast(
                    "Hubo un error ingrasando el email, por favor intente de nuevo.",
                    "error"
                );
            });
    };

    componentDidMount() {
        let {contract, loadPayments} = this.props;
        loadPayments(contract);
    }

    componentDidUpdate(
        prevProps: Readonly<P>,
        prevState: Readonly<S>,
        snapshot: SS
    ): void {
        if (prevProps.contract !== this.props.contract) {
            let {contract, loadPayments} = this.props;
            loadPayments(contract);
        }
    }

    showPaymentInfo(payment) {
        this.setState({
            ...this.state,
            isModalOpen: true,
            paymentResults: payment.payment_results,
        });
    }

    getPaymentUrl(id) {
        const login = AuthService.getLogin();
        if (login.includes("@")) {
            PaymentService.getPaymentUrl(id).then((res) => {
                if (res && res.result === "OK") {
                    window.location = res.payment_url;
                } else {
                    toast(
                        res.msg ||
                        "Hubo un error generando la url de pago, por favor intente de nuevo.",
                        "error"
                    );
                }
            });
        } else {
            this.setState({modalOpen: true});
        }
    }

    getPaymentState(payment) {
        if (payment.expired) {
            return payment.completed ? "Pagado" : "Expirado";
        }
        return payment.completed ? "Pagado" : "Por Pagar";
    }

    render() {
        const {payments} = this.props;
        return (
            <Fragment>
                <Fragment>
                    <div className="row py-3">
                        <div className="col-12">
                            <table className="table table-sm table-striped table-bordered">
                                <tbody>
                                <tr>
                                    <th scope="row">id</th>
                                    <th scope="row">Vence</th>
                                    <th scope="row">Estado</th>
                                    <th scope="row">Valor mes</th>
                                    <th scope="row">Acciones</th>
                                </tr>
                                {payments.map((payment) => (
                                    <tr key={payment.id}>
                                        <td className="align-middle">
                                            {payment.id}
                                        </td>
                                        <td className="align-middle">
                                            {convertToFullDate(payment.due_date)}
                                        </td>
                                        <td className="align-middle">
                                            {this.getPaymentState(payment)}
                                        </td>
                                        <td className="align-middle">
                                            {formatPrice(payment.value_with_tax)}
                                        </td>
                                        <td>
                                            <div className="d-flex justify-content-around flex-column flex-md-row">
                                                {/*payment.from_payment === "ZONA_PAGOS" ?
                                                    <button className="btn btn-primary btn-sm mb-2 mb-md-0"
                                                            onClick={() => this.getPaymentUrl(payment.id)}
                                                            disabled={payment.completed || payment.expired}>
                                                        Pagar
                                                    </button> : */
                                                    <button
                                                        className="btn btn-primary btn-sm mb-2 mb-md-0"
                                                        onClick={() =>
                                                            PaymentService.clickPaymentWidget(payment)
                                                        }
                                                        disabled={payment.completed || payment.expired}
                                                    >
                                                        Pagar
                                                    </button>}
                                                <button
                                                    className="btn btn-primary btn-sm"
                                                    onClick={() => this.showPaymentInfo(payment)}
                                                    disabled={!payment.payment_results.length}
                                                >
                                                    Info
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    {this.state.isModalOpen && (
                        <PaymentInfoModal
                            paymentResults={this.state.paymentResults}
                            onClose={() =>
                                this.setState({...this.state, isModalOpen: false})
                            }
                        />
                    )}
                </Fragment>
                {this.state.modalOpen && (
                    <ModalComponent
                        header="Ingrese su email"
                        footer={
                            <Fragment>
                                <button
                                    type="button"
                                    className="btn btn-primary"
                                    onClick={this.changeEmail}
                                    disabled={this.state.loading}
                                >
                                    Cambiar
                                </button>
                                <button
                                    type="button"
                                    className="btn btn-dark"
                                    disabled={this.state.loading}
                                    onClick={() =>
                                        this.setState({...this.state, modalOpen: false})
                                    }
                                >
                                    Cancelar
                                </button>
                            </Fragment>
                        }
                        body={
                            <Fragment>
                                <form className="mb-3">
                                    <div className="d-flex">
                                        <div className="col-6">
                                            <label htmlFor="inputEmail">Email</label>
                                            <input
                                                name="email"
                                                type="email"
                                                id="inputEmail"
                                                className="form-control"
                                                placeholder="Email"
                                                onChange={this.handleChange}
                                            />
                                        </div>
                                        <div className="col-6">
                                            <label htmlFor="inputPassword">Contraseña Actual</label>
                                            <input
                                                name="password"
                                                type="password"
                                                id="inputPassword"
                                                className="form-control"
                                                placeholder="Contraseña Actual"
                                                onChange={this.handleChange}
                                            />
                                        </div>
                                    </div>
                                </form>

                                <div className="d-flex">
                                    <div className="col-6">
                                        <Alert color="info">
                                            para realizar los pagos online debe tener asociado un
                                            email.
                                        </Alert>
                                    </div>
                                    <div className="col-6">
                                        <Alert color="info">
                                            Al agregar un email, de ahora en adelante solo podra
                                            iniciar sesion con dicho email y la contraseña actual.
                                        </Alert>
                                    </div>
                                </div>
                            </Fragment>
                        }
                        onClose={() => this.setState({...this.state, modalOpen: false})}
                    />
                )}
            </Fragment>
        );
    }
}

export default PaymentComponent;
