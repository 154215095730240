// import React, {Component} from 'react';
// import MainImage from '../../Assets/Images/serviciosMercadeo.jpg';
//
// class RequestReview extends Component <{}, {}> {
//     render() {
//         return (
//             <div className="container-fluid">
//                 <div className="row">
//                     <div className="col-12  col-lg-6">
//                           <span className="col-sm">
//                               <p>La estrategia de Mercadeo:</p>
//                               <ul>
//                                   <li className="text-justify">Contamos con un equipo humano especializado en el área de créditos, con un servicio ágil y veraz en la aprobación de sus solicitudes.</li>
//                                   <li className="text-justify">Realizamos cuidadosamente el estudio de las solicitudes de los arrendatarios, generando la respuesta en un tiempo no mayor de ocho horas. Verificamos y analizamos la documentación para lograr una operación segura del proceso de arrendamiento.</li>
//                               </ul>
//                               <p>
//                                   Si sus coarrendatarios son de fuera del área metrolitana le ofrecemos la posibilidad de arrendar por la Aseguradora El Libertador S.A. y Avalcrear, los cuales cuentan con un personal capacitado y suficiente.
//                               </p>
//                           </span>
//                     </div>
//                     <div className="col-12  col-lg-6">
//                         <img className="services-image img-fluid mb-5 w-75" src={MainImage} alt="not found"/>
//                     </div>
//                 </div>
//             </div>
//         );
//     }
// }

// export default RequestReview;
import React, {Component, Fragment} from 'react';
import MainImage from '../../Assets/Images/DSC_3410.jpg';

class RequestReview extends Component <{},{}>{
    render(){
        return (
            <Fragment>
                <img className = "services-image img-fluid mb-5" src = {MainImage} alt = "not found"/>
                <span className = "col-sm">
            <p>La estrategia de Mercadeo:</p>
            <ul>
              <li className="text-justify">Contamos con un equipo humano especializado en el área de créditos, con un servicio ágil y veraz en la aprobación de sus solicitudes.</li>
              <li className="text-justify">Realizamos cuidadosamente el estudio de las solicitudes de los arrendatarios, generando la respuesta en un tiempo no mayor de ocho horas. Verificamos y analizamos la documentación para lograr una operación segura del proceso de arrendamiento.</li>
            </ul>
            <p>
              Si sus coarrendatarios son de fuera del área metrolitana le ofrecemos la posibilidad de arrendar por la Aseguradora El Libertador S.A. y Avalcrear, los cuales cuentan con un personal capacitado y suficiente.
            </p>
          </span>

            </Fragment>
        );
    }
}

export default RequestReview;