import React, {Component, Fragment} from 'react';
import './Dashboard.scss'
import ContactSummaryService from '../../../Services/ContactSummaryService';
//import {List} from 'react-content-loader';

class AdminDashboard extends Component{

    state = {
      sales: [],
      types: []
    };

  componentDidMount(){
    ContactSummaryService.loadContactSales().then(res => {
      //console.log(res);

      this.setState(previousState => {
        return {sales: res};
      });
    });

    ContactSummaryService.loadContactTypes().then(res => {
      //console.log(res);

      this.setState(previousState => {
        return {types: res};
      });
    });

    //console.log(this.state);
  };

  static calcFlex(count, max) {
    let minBarSize = 0.5, maxCrop = 0.1, places = 2, precision = 10**places;
    return (Math.floor((((count/max)*(1.0-minBarSize-maxCrop))+minBarSize)*precision)/precision);
  }

  render(){
    const sales = this.state.sales;
    let maxSales = 0;
    sales.forEach(function(i) {
      maxSales = maxSales < i.count? i.count : maxSales;
    });
    sales.forEach(function(i) {
      i.flexValue = AdminDashboard.calcFlex(i.count, maxSales);
    });

    const types = this.state.types;
    let maxTypes = 0;
    let c = 0;
    types.forEach(function(i) {
      maxTypes = maxTypes < i.count? i.count : maxTypes;
      i.id = c;
      c++;
    });
    types.forEach(function(i) {
      i.flexValue = AdminDashboard.calcFlex(i.count, maxTypes);
    });

    return (
      <div className="container">
        <Fragment>
          {
            sales.length &&
            <Fragment>
              <div className="row pt-2">
                <div className="container">
                  <div className="row py-1">
                    <div className="col-12">
                      <h3 className = "text-primary text-center text-ayu-bold">
                        Contactos Abiertos(Pendientes/Procesando)
                      </h3>
                    </div>
                  </div>
                  <div className="row py-4">
                    <div className="col-12">
                      <h5 className = "text-primary text-center text-ayu-bold">
                       por Asesor(a)
                      </h5>
                    </div>
                  </div>
                </div>
              </div>

              <div className="info-table">
                {sales.map(i => (
                  <div className="row ml-0 mr-3 my-2" key={i.id}>
                    <div className="bar pl-1 py-2" style={{flex: i.flexValue}}>
                          {i.name} ({i.count})
                    </div>
                    <div style={{flex: 1-i.flexValue}}>

                    </div>
                  </div>
                ))}
              </div>
            </Fragment>
          }


          {
            types.length &&
            <Fragment>
              <div className="row pt-5">
                <div className="container">
                  <div className="row py-1">
                    <div className="col-12">
                      <h3 className = "text-primary text-center text-ayu-bold">
                        Contactos Abiertos(Pendientes/Procesando)
                      </h3>
                    </div>
                  </div>
                  <div className="row py-4">
                    <div className="col-12">
                      <h5 className = "text-primary text-center text-ayu-bold">
                       por Tipo
                      </h5>
                    </div>
                  </div>
                </div>
              </div>

              <div className="info-table">
                {types.map(i => (
                    <div className="row ml-0 mr-3 my-2" key={i.id}>
                      <div className="bar pl-1 py-2" style={{flex: i.flexValue}}>
                        {i.type} ({i.count})
                      </div>
                      <div style={{flex: 1-i.flexValue}}>

                      </div>
                    </div>
                ))}
              </div>
            </Fragment>
          }
        </Fragment>
      </div>
    );
  }
}


export default AdminDashboard;
