import React, {Component, Fragment} from 'react';
import SecondImage from '../../Assets/Images/ServiceClientPersonal.jfif';

class CustomerService extends Component <{},{}>{
  render(){
    return (
        <Fragment>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12  col-lg-8  ">
                              <span className = "col-sm">
                                <ul>
                                  <li className="text-justify">En nuestras instalaciones, ofrecemos cómodos y amables espacios para prestarles una atención personalizada, ágil y oportuna.</li>
                                  <li className="text-justify">Tenemos un equipo humano atento a resolver inquietudes con respecto a los servicios inmobiliarios que ofrecemos.</li>
                                  <li className="text-justify">Contamos con personal especializado en atención a administraciones de propiedad horizontal de los inmuebles que tenemos en consignación.</li>
                                  <li className="text-justify">Usted es el motivo para mejorar nuestro servicio y su satisfacción, nuestro reto diario.</li>
                                  <li className="text-justify">Agilidad en la atención, requerimientos e inquietudes de nuestros propietarios.</li>
                                </ul>

                              </span>
                    </div>
                    {/*<div className="col-12  col-lg-6">*/}
                    {/*    /!*<img className="services-image img-fluid mb-5 " style={{width:"70%"}} src={MainImage} alt="not found"/>*!/*/}
                    {/*</div>*/}
                </div>
                <div className="mt-4">
                    <p>Escuchamos sus inquietudes ya que solo así podremos corregirlo para en el futuro brindarle la excelencia en la calidad del servicio que usted se merece.</p>
                    <img className = "services-image img-fluid mb-5" src = {SecondImage} alt = "not found"/>
                </div>
               </div>


        </Fragment>
    );
  }
}

export default CustomerService;
