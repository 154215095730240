import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import CodeIcon from '../../Assets/Images/code-icon.png';
import BathIcon from '../../Assets/Images/bathroom-icon.png';
import SalonIcon from '../../Assets/Images/salon.png';
import AreaIcon from '../../Assets/Images/area-icon.png';
import RoomIcon from '../../Assets/Images/room-icon.png';
import PlaceholderImage from '../../Assets/Images/noDisponible.jpg';
import { imageUrl } from '../../Utils/Config';
import { formatPrice } from '../../Utils/Utilities';
import PropertyDetails from '../../Utils/PropertyDetails';

type Props = {
	property: Object,
};

class PropertyCard extends Component<Props, {}> {
	state = {
		property: {},
	};

	componentDidMount(): void {
		const property = { ...this.props.property };

		if (property.cover) {
			property.cover = property.cover.replace('_s', '_l');
		}
		property.conditionalDetail = this.findPropertyDetailValue(PropertyDetails.N_ALCOBAS) ? (
			<>
				<img className='mr-1' src={RoomIcon} alt='' />
				<span className=''>
					<b>Habitaciones:</b>
					{this.findPropertyDetailValue(PropertyDetails.N_ALCOBAS)}
				</span>
			</>
		) : this.findPropertyDetailValue(PropertyDetails.SALON) ? (
			<>
				<img className='mr-1' src={SalonIcon} alt='' />
				<span className=''>
					<b>Salon:</b>
					{this.findPropertyDetailValue(PropertyDetails.SALON)}
				</span>
			</>
		) : (
			<></>
		);

		property.noBathroom = this.findPropertyDetailValue(PropertyDetails.N_BAÑOS) || null;
		property.formatPrice = formatPrice(property.price);

		this.setState({ property });
	}

	findPropertyDetailValue = (propertyDetail) => {
		let property = this.props.property.details.find((detail) => propertyDetail === detail.name);

		// If the property was not found, returns empty, otherwise throws an error
		return property ? property.value : '';
	};

	render() {
		const { property } = this.state;

		return (
			<Link
				style={{ textDecoration: 'none' }}
				to={{ pathname: '/property', search: '?id=' + property.id }}
			>
				<div className='card card-property clicka'>
					<div className='card-property-image-container'>
						<div
							className='cart-property-image d-flex align-items-end'
							style={{
								width: '100%',
								height: '100%',
								backgroundImage: `url(${imageUrl}${property.cover}), url(${PlaceholderImage})`,
								backgroundSize: 'cover',
								backgroundRepeat: 'no-repeat',
							}}
						>
							<div className='card-property-name'>
								{property.neighborhood} - {property.city}
							</div>
						</div>
					</div>
					<div className='card-body card-property-body'>
						<h3 className='text-primary mb-2'>
							<b>${property.formatPrice}</b>
						</h3>
						<div className='container-flush'>
							<div className='row'>
								<div className='col-6'>
									<img className='mr-1' src={CodeIcon} alt='' />
									<span className='d-inline-block'>
										<b>Código:</b> #{property.id}
									</span>
								</div>
								<div className='col-6'>{property.conditionalDetail}</div>
								<div className='col-6'>
									<img className='mr-1' src={AreaIcon} alt='' />
									<span className=''>
										<b>Área:</b> {property.area}m<sup>2</sup>
									</span>
								</div>
								<div className='col-6'>
									{property.noBathroom && (
										<>
											<img className='mr-1' src={BathIcon} alt='' />
											<span className=''>
												<b>Baños:</b>
												{property.noBathroom}
											</span>
										</>
									)}
								</div>
							</div>
						</div>
					</div>
				</div>
			</Link>
		);
	}
}

export default PropertyCard;
