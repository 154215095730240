import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import PropertyCard from './PropertyCardComponent';
import {bindActionCreators} from 'redux';
import {loadFinish, loadProperties} from '../../Redux/ActionTypes';
import ContentLoader from 'react-content-loader';
import queryString from 'query-string';
import {Pagination, PaginationItem, PaginationLink} from 'reactstrap';
import {withRouter} from 'react-router-dom';

type State = {
  currentPage: number
};

class PropertiesList extends Component<{}, State> {
  constructor(props) {
    super(props);
    const page = parseInt(this.props.history.location.search.match('page=([1-9])'));
    this.state = {currentPage: page ? page[1] : 1};
  }

  rotueActive = id => {
    return this.state.currentPage === id;
  };

  nextPage = add => {
    this.setState((prev, actualProps) => {
        if (
          prev.currentPage + add <= 0 ||
          prev.currentPage + add > this.props.pages
        ) {
          return {...prev};
        }
        this.props.loadProperties({
          ...this.props.params,
          page: this.state.currentPage + add,
          size: 6
        });
        return {...prev, currentPage: prev.currentPage + add};
      }, () => {
        if (this.props.history.location.pathname === '/buscar') {
          this.props.history.replace({
              pathname: '/buscar',
              search: this.props.history.location.search.replace(`page=${this.state.currentPage - add}`, `page=${this.state.currentPage}`)
            }
          );
        }
      }
    );
  };

  fechData = (props: object) => {
    const {loadFinish, location, loadProperties} = this.props;
    console.log(this.props);
    loadFinish();
    let param = {};

    if (location) {

      const queryParams = queryString.parse(location.search);
      Object.keys(queryParams).forEach(i => {
        if (!queryParams[i]) {
          return;
        }
        param[i] = queryParams[i];
      });
    }
    loadProperties({...param, size: 6});
  };

  componentDidMount() {
    this.fechData();
  }

  componentDidUpdate(prevProps
                       :
                       Object, prevState
                       :
                       Object
  ) {

    //Se actualiza la currentPage a 1 si se realiza una nueva busqueda
    if(this.props.params.page !== this.state.currentPage && this.props.params.page){
      this.setState({currentPage: this.props.params.page});
    }

    if (prevProps.location) {
      if (this.props.location.search !== prevProps.location.search) {
        this.fechData();
      }
    }
  }

  render() {
    const {list, pages, loadSpinner} = this.props;
    if (!loadSpinner) {
      return <div style={{width: '100%'}}>
        <ContentLoader height={375} width={565} speed={3} primaryColor="#f3f3f3" secondaryColor="#ecebeb">
          <rect x="10" y="10" rx="5" ry="5" width="172" height="172"/>
          <rect x="192" y="10" rx="5" ry="5" width="172" height="172"/>
          <rect x="374" y="10" rx="5" ry="5" width="172" height="172"/>
          <rect x="10" y="192" rx="5" ry="5" width="172" height="172"/>
          <rect x="192" y="192" rx="5" ry="5" width="172" height="172"/>
          <rect x="374" y="192" rx="5" ry="5" width="172" height="172"/>
        </ContentLoader>
      </div>;
    }
    if (list && list.length) {
      return (
        <Fragment>
          <div className="row align-item-center justify-content-center">
            {list.map(i => (
              <div
                className="col-lg-4 col-12 col-sm-6 mb-4"
                key={i.id + 'list'}>
                <PropertyCard property={i}/>
              </div>
            ))}
          </div>
          <Pagination
            aria-label="Page navigation example"
            className="d-flex justify-content-center">
            <PaginationItem>
              <PaginationLink
                className="border-0 bg-transparent"
                onClick={() => this.nextPage(-1)}>
                <i className="fa fa-arrow-left"/>
              </PaginationLink>
            </PaginationItem>
            <div className="d-flex justify-content-center align-items-center">
              {this.state.currentPage} de {pages}
            </div>
            <PaginationItem>
              <PaginationLink
                className="border-0 bg-transparent"
                onClick={() => this.nextPage(+1)}

              >
                <i className="fa fa-arrow-right"/>
              </PaginationLink>
            </PaginationItem>
          </Pagination>
        </Fragment>
      );
    }
    return (
      <div className="row align-item-center justify-content-center mb-4">
        <h2>No se encontraron resultados</h2>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const {list, params, pages, loadFinish} = state.PropertiesReducer;
  return {
    list,
    params,
    pages,
    loadSpinner: loadFinish
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({loadProperties, loadFinish}, dispatch);
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(PropertiesList)
);
