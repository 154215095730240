import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './AppComponent';
import * as serviceWorker from './serviceWorker';
import {Provider} from 'react-redux';
import store from './Redux/Index';
import 'izitoast/dist/css/iziToast.min.css';


const root: ?Element = document.getElementById('root');


if (root != null) {
    ReactDOM.render(
        <Provider store={store}>
                <App/>
        </Provider>,
        root
    );
    serviceWorker.unregister();
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
