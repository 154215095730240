import React, {Component, Fragment} from 'react';
import '../node_modules/react-smartbanner/dist/main.css';
import SmartBanner from 'react-smartbanner';
import './Styles/_app.scss';
import 'react-image-lightbox/style.css';
import {HashRouter as Router, Route, Switch} from 'react-router-dom';
import DashboardLandingComponent from './Components/DashboardLanding/DashboardLandingComponent';
import DirectoryComponent from './Components/Directory/DirectoryComponent';
import RequestCheckComponent from './Components/RequestCheckComponent/RequestCheckComponent';
import AdminMainComponent from './Components/Admin/AdminMainComponent';
import ScrollToTop from "./Components/Shared/ScrollToTop";
import RepairDetailComponent from "./Components/Repair/RepairDetailComponent";

class App extends Component<{}, {}> {
	componentDidMount() {
	}

	render() {
		return (
			<Router>
				<Fragment>
					ReactDOM.render(<SmartBanner title={'Arrendamientos Ayurá'}
																			 storeText={{android: 'En Google Play'}}
																			 price={{android: 'Gratis'}}
																			 button={'Instalar'}
																			 daysHidden={5}
				/>, document.getElementById('content'));
					<ScrollToTop />
					<Switch>
						<Route
							path="/contratos"
							render={() => (
								<DirectoryComponent owner={true}/>
							)}
						/>

						<Route
							path="/inquilino"
							render={() => (
								<DirectoryComponent owner={false}/>
							)}
						/>

						<Route
							path="/admin"
							render={() => (
								<AdminMainComponent owner={false}/>
							)}
						/>

						<Route
							path="/consultas"
							render={() => (
								<RequestCheckComponent/>
							)}
						/>

						<Route
							path="/reparaciones/detalle/:contract/:id"
							render={() => (
								<RepairDetailComponent/>
							)}
						/>

						<Route path="/" component={DashboardLandingComponent}/>
					</Switch>
				</Fragment>
			</Router>
		);
	}
}

export default App;
