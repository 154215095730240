import { format } from 'date-fns';
import * as es from 'date-fns/locale/es' ;
import AuthService from '../Services/AuthService';
import {toast} from '../Services/AlertService';
import living from '../Assets/Images/icons-8-living-room@3x.png';
import dining from '../Assets/Images/comedor.png';
import fridge from '../Assets/Images/icons-8-fridge@3x.png';
import gas from '../Assets/Images/icons-8-gas-industry@3x.png';
import backyard from '../Assets/Images/patios@3x.png';
import vestier from '../Assets/Images/vestier@3x.png';
import room from '../Assets/Images/room-icon@3x.png';
import bath from '../Assets/Images/bathroom-icon@3x.png';
import indoorParking from '../Assets/Images/icons-8-indoor-parking@3x.png';
import closet from '../Assets/Images/icons-8-closet@3x.png';
import stairs from '../Assets/Images/icons-8-stairs-up@3x.png';
import phone from '../Assets/Images/icons8-phone.png';
import library from '../Assets/Images/biblioteca@3x.png';
import level from '../Assets/Images/icons-8-stairs-up@3x.png';
import hall from '../Assets/Images/hall@3x.png';
import balcony from '../Assets/Images/icons-8-balcony@3x.png';
import frontyard from '../Assets/Images/terraza@3x.png';
import bathCabin from '../Assets/Images/icons-8-bidet@3x.png';
import bidet from '../Assets/Images/icons-8-bidet@3x.png';
import elevator from '../Assets/Images/ascensor@3x.png';
import coniferous from '../Assets/Images/icons-8-coniferous-tree@3x.png';
import park from '../Assets/Images/juegos@3x.png';
import hotWater from '../Assets/Images/icons-8-room-sound@3x.png';
import gate from '../Assets/Images/porteria@3x.png';
import pool from '../Assets/Images/piscina@3x.png';
import cushion from '../Assets/Images/icons-8-cushion@3x.png';
import salon from '../Assets/Images/salon@3x.png';
import sauna from '../Assets/Images/sauna@3x.png';
import sports from '../Assets/Images/zona-deportiva@3x.png';
import tv from '../Assets/Images/hd-tv@3x.png';
import closedUnit from '../Assets/Images/unidad-cerrada@3x.png';

const crypto = window.crypto ||
  window.msCrypto || {
    getRandomValues: array => {
      for (let i = 0, l = array.length; i < l; i++) {
        array[i] = Math.floor(Math.random() * 256);
      }
      return array;
    }
  };

export function convertToFullDate(date) {
  return format(date.toString(), 'dddd, MMMM DD, YYYY', {locale: es});
}

export const loginTenant = (id: number, password: number) => {
  AuthService.login({login: id, password}).then((res: any) => {
    let type, message;
    if(res.success){
      type = 'success';
      message = 'Login Successful';
      window.location.replace(window.location.origin + '/#/inquilino');
    } else{
      type = 'error';
      message = res.msg;
    }
    toast(message, type);
  });
};

export function setParamsToUrl(url, params) {
  Object.keys(params).forEach(key => {
    url = url.replace(`$${key}$`, params[key]);
  });
  return url;
}

export function uuidV4() {
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
    (((c ^ crypto.getRandomValues(new Uint8Array(1))[0]) & 15) >> (c / 4)).toString(16)
  );
}

export function sortArray(array: Array<any>, key: string, asc ?: boolean = true): Array<any>{
  const tmpArray = array.slice();

  tmpArray.sort((a, b) => {
    let tmp = a - b;
    if(key){
      const type = typeof a[key];
      if(type === "string"){
        if(a[key] < b[key]){
          tmp = -1;

        } else{
          if(a[key] > b[key]){
            tmp = 1;

          } else{
            tmp = 0;
          }

        }
      } else{
        tmp = a[key] - b[key];
      }
    }
    return asc ? tmp : -tmp
  });
  return tmpArray;
}

export function formatPrice(price: number){
  return new Intl.NumberFormat('es-419', {
    maximumFractionDigits: 0
  }).format(price);
}

export const icons = {
  'Sala': living,
  'Sala Comedor': living,
  'Comedor': dining,
  'Comedor Auxiliar': dining,
  'Cocina': fridge,
  'Estufa': gas,
  'Horno': gas,
  'Gas': gas,
  'N. Patios': backyard,
  'N. Vestier': vestier,
  'N. Alcobas': room,
  'N. Baños': bath,
  'N. Garajes': indoorParking,
  'N. Closets': closet,
  'Nivel #': stairs,
  'Lineas Telefónicas': phone,
  'Alcoba Servicio': room,
  'Biblioteca': library,
  'Hall': hall,
  'Mezanine': level,
  'Mirador': balcony,
  'Balcon': balcony,
  'Terraza': frontyard,
  'Zona Ropas': closet,
  'Baño Servicio': bath,
  'Baño Cabina': bathCabin,
  'Cuarto Útil': room,
  'Ascensor': elevator,
  'Nombre Urbanización': coniferous,
  'Juegos': park,
  'Jacuzzi': bidet,
  'Agua caliente': hotWater,
  'Parqueadero': indoorParking,
  'Parqueadero Cubierto': indoorParking,
  'Portería': gate,
  'Portería 24 Horas': gate,
  'Piscina': pool,
  'Pisos': cushion,
  'Salón Social': salon,
  'Sauna': sauna,
  'Turco': bidet,
  'Zonas Verdes': coniferous,
  'Zona Deportiva': sports,
  'TV por cable': tv,
  'Unidad Cerrada': closedUnit,
  'Gimnasio': frontyard,
  'BBQ': backyard,
  // 'Citofono': salon,
  // 'Shute': salon,
};

export const randomNum = (n) => {
  return Math.floor(Math.random() * Math.floor(n));
};

export const validate = () => {
  var currentToken = localStorage.getItem('token');
  setInterval(function () {
    const token = localStorage.getItem('token');
    if ((currentToken && token) && currentToken !== token) {
      currentToken = token;
      window.location = '/';
    }
  }, 5000);
}