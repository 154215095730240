import React, {Component, Fragment} from 'react';
import './Documents.scss';
import {format} from 'date-fns';
import {Pagination, PaginationItem, PaginationLink} from 'reactstrap';
import Lightbox from 'react-image-lightbox';
import DropZoneUploader from './DropZoneUploader';
import PaymentComponent from '../../Directory/PaymentComponent/PaymentComponent';
import {Link} from "react-router-dom";
import LoadingComponent from "../Loadig/LoadingComponent";
// import LightBoxComponent from "../LightBox/LightBoxComponent";
const REPAIR_FOLDER_ID = parseInt(process.env.REACT_APP_REPAIR_FOLDER_ID)
class Documents extends Component<{}, state> {

    state = {
        // tab: 'docs',
        tab: 'repair',
        photoIndex: 0,
        message: '',
        isOpen: false,
        files: [],
        orderAscName: true,
        orderAscDate: false,
        asList: false,
        documents: [],
        payment_status: '',
        selectToRepair: false,
        contract: null
    };
    docIds = this.props.owner ? [-1, -2, 18, 19, 20, 21, 22, 23, 24, 30, 44, 130] : [-1, -2, 2, 19, 20, 22, 23, 30, 41, 42, 125, 130, REPAIR_FOLDER_ID];

    openImage(i) {
        const photoIndex = this.props.photos.indexOf(i.content_path);
        this.setState({isOpen: true, photoIndex});
    }

    onDrop(files) {
        this.setState({files});
    }

    sendComment() {
        this.props.sendComment(this.state.message);
        this.setState({...this.state, message: ''});
    }

    sendPublicServices = () => {
        this.props.uploadPublicServices(this.props.contract, this.state.files);
        this.setState({...this.state, files: []});
    };

    canAddFile() {
        return (!!this.props.request || (!this.props.owner && this.props.folderId === 23));
    }

    handleChange = event => {
        const {value, name} = event.target;
        this.setState({[name]: value});
    };

    handleOrderByName = () => {
        if (this.state.orderAscName === false) {
            this.setState({...this.state, orderAscName: true});
        } else {
            this.setState({...this.state, orderAscName: false});
        }
    };

    handleOrderByDate = () => {
        if (this.state.orderAscDate === false) {
            this.setState({...this.state, orderAscDate: true});
        } else {
            this.setState({...this.state, orderAscDate: false});
        }
    };

    handleAsList = () => {
        if (this.state.asList === false) {
            this.setState({...this.state, asList: true});
        } else {
            this.setState({...this.state, asList: false});
        }
    };

    componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS): void {

        if (prevProps.documents !== this.props.documents) {
            this.setState({...this.state, documents: this.props.documents})
            return;
        }

        if (prevProps.contract !== this.props.contract) {
            this.setState({...this.state, contract: this.props.contracts.find(x => x.contract_code === this.props.contract)})
        }

        //Order By Name
        if (this.state.orderAscName) {
            if (prevState.orderAscName !== this.state.orderAscName) {
                if (this.state.documents.length && this.state.documents[0].icon_type === "Folder") {
                    // this.setState({...this.state, documents: this.state.documents.sort((a, b) =>  a.name >  b.name ? 1 : -1)});
                    this.setState({
                        ...this.state,
                        documents: this.state.documents.sort((a, b) => a.name.localeCompare(b.name))
                    });

                } else {
                    // this.setState({...this.state, documents: this.state.documents.sort((a, b) =>  a.file_name >  b.file_name ? 1 : -1)});
                    this.setState({
                        ...this.state,
                        documents: this.state.documents.sort((a, b) => a.file_name.localeCompare(b.file_name))
                    });
                }
            }
        } else {
            if (prevState.orderAscName !== this.state.orderAscName) {
                if (this.state.documents.length && this.state.documents[0].icon_type === "Folder") {
                    this.setState({
                        ...this.state,
                        documents: this.state.documents.sort((a, b) => b.name.localeCompare(a.name))
                    });
                } else {
                    this.setState({
                        ...this.state,
                        documents: this.state.documents.sort((a, b) => b.file_name.localeCompare(a.file_name))
                    });
                }
            }
        }

        //Order By Date
        if (this.state.orderAscDate) {
            if (prevState.orderAscDate !== this.state.orderAscDate) {
                if (this.state.documents.length && this.state.documents[0].icon_type === "Document") {
                    this.setState({
                        ...this.state,
                        documents: this.state.documents.sort((a, b) => b.created.localeCompare(a.created))
                    });
                }
            }
        } else {
            if (prevState.orderAscDate !== this.state.orderAscDate) {
                if (this.state.documents.length && this.state.documents[0].icon_type === "Document") {
                    this.setState({
                        ...this.state,
                        documents: this.state.documents.sort((a, b) => a.created.localeCompare(b.created))
                    });
                }
            }
        }
    }


    toggleTab(tab) {
        if (this.state.tab !== tab) {
            this.setState({...this.state, tab});
        }
    }


    render() {
        const {tab, message, isOpen, photoIndex} = this.state;
        const {
            contract, request, mainLoad, loadRoot, folder, documents,
            openDoc, pages, comments, currentPage, nextPage, photos, owner,
            loadPayments, payments, showLoading, subdirectory, loadRepairDirectories, folderId
        } = this.props;

        const ToBackDirectory = () => {
            return (
                !mainLoad && (
                    <div className="row">
                        <div
                            className={`col-${photos && photos.length ? '10' : '12'} py-1 text-center bg-secondary text-white doc-clickable`}
                            onClick={() => {
                                subdirectory ? loadRepairDirectories() : loadRoot()
                            }}> {folder} <i
                            className="fa fa-level-up fa-lg"/></div>
                        {!!(photos && photos.length) &&
                        <div
                            className="col-2 py-1 text-center bg-secondary text-white doc-clickable border-left"
                            onClick={() => this.setState({isOpen: true, photoIndex: 0})}>
                            Ver fotos <i className="fa fa-file-photo-o"/>
                        </div>}
                    </div>)
            );
        }

        const Paginator = () => {
            return !mainLoad && !!documents.length && pages && (
                <div className="row d-flex justify-content-center">
                    <Pagination
                        aria-label="Page navigation example"
                        className="d-flex justify-content-center">
                        <PaginationItem>
                            <PaginationLink
                                className="border-0 bg-transparent"
                                onClick={() => nextPage(-1)}>
                                <i className="fa fa-arrow-left"/>
                            </PaginationLink>
                        </PaginationItem>
                        <div className="d-flex justify-content-center align-items-center">
                            {currentPage} de {pages}
                        </div>
                        <PaginationItem>
                            <PaginationLink
                                className="border-0 bg-transparent"
                                onClick={() => nextPage(1)}

                            >
                                <i className="fa fa-arrow-right"/>
                            </PaginationLink>
                        </PaginationItem>
                    </Pagination>
                </div>
            )
        }

        const ViewType = () => {
            return (
                <div className="row mt-3 mb-3">
                    {this.state.documents.length ?
                        <div className="d-flex w-100">
                            <div className="col-8">
                      <span className={`${!this.state.asList && "d-none"}`} style={{cursor: "pointer"}}
                            onClick={() => this.handleOrderByName()}>
                        <strong>Nombre</strong> <i
                          className={`${this.state.orderAscName ? "fa fa-sort-amount-asc" : "fa fa-sort-amount-desc"}`}/></span>
                            </div>
                            <div className="col-3 text-center">
                      <span
                          className={`ml-2 ${(!this.state.asList || this.state.documents[0].icon_type === "Folder") && "d-none"}`}
                          onClick={() => this.handleOrderByDate()} style={{cursor: "pointer"}}><strong>Fecha</strong>
                      <i className={`${!this.state.orderAscDate ? "fa fa-sort-amount-asc" : "fa fa-sort-amount-desc"}`}/></span>
                            </div>
                            <div className="col-1 text-right">
                                                    <span style={{cursor: "pointer"}} onClick={this.handleAsList}><i
                                                        className={`${this.state.asList ? "fa fa-th" : "fa fa-list"}`}/></span>
                            </div>
                        </div> : null
                    }
                </div>
            )
        }

        const File = (item, index) => {
            return (
                <div key={item.id || item.doc_type_id || `file_${index}`}
                     className={`doc-clickable${!this.state.asList ? ' col-md-3 my-4' : ''}`}>
                    {
                        this.state.asList ?
                            <ul className="list-group">
                                <li className="list-group-item d-flex p-1">
                                    <div
                                        className={`${item.icon_type === 'Document' ? "col-8" : "col-12"}`}>
                                      <span
                                          onClick={() => (item.icon_type === 'Document' && item.file_name.indexOf('JPG') !== -1) ? this.openImage(item) : openDoc(item)}>
                                          <span
                                              className="text-primary">{(item.icon_type === 'Folder' ? item.name : item.file_name).toLowerCase()}</span></span>
                                    </div>

                                    <div
                                        className={`${item.icon_type === 'Document' ? "col-4" : "d-none"}`}>
                                        <span>{item.created}</span>
                                    </div>
                                </li>
                            </ul>
                            :
                            <span
                                onClick={() => (item.icon_type === 'Document' && item.file_name.indexOf('JPG') !== -1) ? this.openImage(item) : openDoc(item)}>
                                <i
                                    className={`fa fa-2x d-flex justify-content-center ${item.icon_type === 'Folder' ? 'fa-folder' : (item.content_type.split('/')[0] !== 'image'?'fa-file-text-o':'fa-file-image-o')}`}/>
                                <span
                                    className="text-primary">{String(item.icon_type === 'Folder' ? item.name : item.file_name).toLowerCase()}</span>
                              </span>
                    }
                </div>
            )
        }

        const ListFile = () => {


            return (
                <div className={`row  ${this.state.asList ? "text-left d-block" : "text-center"}`}>
                    {

                        this.props.showLoadingDocuments? <LoadingComponent show={this.props.showLoadingDocuments}/> : documents.length   ?
                                documents
                                .filter((i) => (subdirectory || folderId === REPAIR_FOLDER_ID || this.docIds.includes(i.doc_type_id) || request))
                                .map((i, index) => File(i, index))
                            :
                            <div className="col-12 my-4"> No se han encontrado Documentos Existentes</div>
                    }
                </div>
            );
        }

        return (
            <div className="container py-4">
                <div className="row">
                    <div onClick={() => this.toggleTab('docs')} className={`col-2 col-md-3 tab-parent`}>
                        <div className={`doc-container-header pt-2 clickable ${tab === 'docs' ? 'tab-active' : ''}`}>
                            <span className="d-none d-md-flex text-white">Documentos</span>
                            <i className={'d-md-none fa d-flex justify-content-center fa-file-text-o text-white'}/>
                        </div>
                    </div>
                    {
                        request &&
                        <div onClick={() => this.toggleTab('msg')} className={`col-2 col-md-4 clickable`}>
                            <div className={`doc-container-header pt-2 clickable ${tab === 'msg' ? 'tab-active' : ''}`}>
                                <span className="d-none d-md-flex text-white">Mensajes</span>
                                <i className={'d-md-none fa d-flex justify-content-center fa-envelope-open text-white'}/>
                            </div>
                        </div>
                    }
                    {
                        !owner && !request &&
                        <Fragment>
                            <div onClick={() => this.toggleTab('upload')} className={`col-2 col-md-3 tab-parent`}>
                                <div
                                    className={`doc-container-header pt-2 clickable ${tab === 'upload' ? 'tab-active' : ''}`}>
                                    <span className="d-none d-md-flex text-white">Servicios Públicos</span>
                                    <i className={'d-md-none fa d-flex justify-content-center fa-plug text-white'}/>
                                </div>
                            </div>
                            <div onClick={() => this.toggleTab('payment')} className={`col-2 col-md-3 tab-parent`}>
                                <div
                                    className={`doc-container-header pt-2 clickable ${tab === 'payment' ? 'tab-active' : ''}`}>
                                    <span className="d-none d-md-flex text-white">Pagos</span>
                                    <i className={'d-md-none fa d-flex justify-content-center fa-dollar text-white'}/>
                                </div>
                            </div>
                        </Fragment>
                    }
                    {
                        // !owner &&
                        !request &&
                        <Fragment>
                            <div onClick={() => this.toggleTab('repair')} className={`col-2 col-md-3 tab-parent`}>
                                <div
                                    className={`doc-container-header pt-2 clickable ${tab === 'repair' ? 'tab-active' : ''}`}>
                                    <span className="d-none d-md-flex text-white">Reparaciones</span>
                                    <i className={'d-md-none fa d-flex justify-content-center fa-dollar text-white'}/>
                                </div>
                            </div>
                        </Fragment>
                    }
                </div>

                <div className="row">
                    <div className="container doc-container">
                        {
                            tab === 'docs' && (
                                <Fragment>
                                    {ToBackDirectory()}
                                    {!mainLoad && this.canAddFile() && (
                                        <DropZoneUploader
                                            onDrop={this.onDrop}
                                            files={this.state.files}
                                            cancel={() => {
                                                this.setState({files: []});
                                            }}
                                            uploadFile={this.uploadFile}/>
                                    )}
                                    {ViewType()}
                                    {ListFile()}
                                    {Paginator()}
                                </Fragment>)}
                        {
                            tab === 'msg' && (
                                <div className="row">
                                    <div className="col-6">
                                        <ul className="list-unstyled comment-list p-3">
                                            {comments.map(i => (
                                                <li key={i.id}>
                                                    [{format(i.created, 'DD/MM/YYYY hh:mm A')}] {i.name} Escribió:<br/>
                                                    {i.comment}
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                    <div className="col-6">
                                        <label className="text-ayu-bold">
                                            Enviar Mensaje:
                                        </label>
                                        <textarea name="message"
                                                  value={message}
                                                  onChange={this.handleChange}
                                                  className="form-control mb-3 no-resize"
                                                  placeholder="Mensaje" rows="3"/>
                                        <div className="d-flex justify-content-end">
                                            <button className="btn btn-secondary send-button"
                                                    onClick={() => this.sendComment()}>
                                                Enviar
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            )}

                        {
                            tab === 'upload' &&
                            <div className="pb-2 mt-2">
                                <div className="d-flex justify-content-center">
                                    <label className="text-ayu-bold">
                                        Utilice este formulario para enviar sus comprobantes de servicios públicos.
                                    </label>
                                </div>
                                {<DropZoneUploader
                                    onDrop={this.onDrop.bind(this)}
                                    files={this.state.files}
                                    cancel={() => {
                                        this.setState({files: []});
                                    }}
                                    uploadFile={this.sendPublicServices}/>}
                            </div>
                        }
                        {
                            tab === 'payment' &&
                            <PaymentComponent contract={contract} payments={payments} loadPayments={loadPayments}/>
                        }

                        {
                            tab === 'repair' &&
                            <div className="pb-2 mt-2">
                                <div className="row">
                                    <div className="col-12">
                                        {
                                            //!owner && this.state.contract && ( new Date() < new Date(this.state.contract.contract_end_date) ) &&
                                            <div className={"float-right"}
                                                 style={{marginTop: "10px", marginBottom: "18px"}}>
                                                <button className={'btn btn-primary'}
                                                        onClick={this.props.repair}>Solicitar reparación -
                                                    #{contract}</button>
                                            </div>
                                        }
                                    </div>
                                    <div className="col-12 d-flex justify-content-center">
                                        <LoadingComponent show={showLoading}/>
                                    </div>
                                    {
                                        !showLoading &&
                                        <div className="col-12">
                                            <table className={"table table-sm table-striped table-bordered"}>
                                                <thead>
                                                <tr>
                                                    <th>Cons. Encabezado</th>
                                                    {/*<th>Cons. Web</th>*/}
                                                    <th>Fecha</th>
                                                    <th>Ver detalles</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {
                                                    this.props.repairs.map(repair => (
                                                        <tr key={`repair_${repair["Cons_Web"]}`}>
                                                            <td>{repair["Cons_Encabezado"]}</td>
                                                            <td>{repair["Fecha"]}</td>
                                                            <td>
                                                                <Link
                                                                    to={`/reparaciones/detalle/${this.props.contract}/${repair["Cons_Encabezado"]}`}
                                                                    className={"btn btn-outline-primary"}>
                                                                    <i className={"fa fa-eye"}/>
                                                                </Link>
                                                            </td>
                                                        </tr>
                                                    ))
                                                }
                                                </tbody>
                                            </table>
                                        </div>
                                    }
                                </div>
                            </div>
                        }
                    </div>
                </div>
                {
                    isOpen && (
                        <Lightbox reactModalStyle={{overlay: {'zIndex': 2000}}}
                                  mainSrc={photos[photoIndex].replace('_s', '_l')}
                                  nextSrc={photos[(photoIndex + 1) % photos.length]}
                                  prevSrc={photos[(photoIndex + photos.length - 1) % photos.length]}
                                  onCloseRequest={() => this.setState({isOpen: false})}

                                  onMovePrevRequest={() =>
                                      this.setState({
                                          photoIndex: (photoIndex + photos.length - 1) % photos.length
                                      })
                                  }
                                  onMoveNextRequest={() =>
                                      this.setState({
                                          photoIndex: (photoIndex + 1) % photos.length
                                      })
                                  }
                        />
                    )}
            </div>
        );
    }

}

export default Documents;
