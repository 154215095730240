import React, {Component} from 'react';
// import MainImage from '../../Assets/Images/DSC_3525.jpg';

class Relocation extends Component <{},{}>{
  render(){
    return (
        <div className="container-fluid">
          <div className="row">
            <div className="col-12  col-lg-6 mt-5">
              <ul>
                <li className="text-justify">Atender en forma oportuna y correcta los clientes que desean ser reubicados por medio de la empresa, buscando suplir cada una de sus necesidades en el menor tiempo posible.</li>
                <br/>
                <li className="text-justify">Asesora Ana María Molina tel: <a href = "tel:035+ 2766856">2766856</a></li>
              </ul>
            </div>
          </div>
        </div>
    );
  }
}

export default Relocation;