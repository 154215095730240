import { get, post } from "../NetworkLayer/Index";
import { URL } from "../Utils/Config";
import { setParamsToUrl } from "../Utils/Utilities";
import environment from "../environment";

export default class PaymentService {
  static clickPaymentWidget(payment) {
    const paymentConfig = {
      currency: "COP",
      // Por que tengo que poner por 100?????
      amountInCents: payment.value_with_tax * 100,
      reference: `${payment.id.toString()}${
        payment.payment_results.length > 0
          ? `R${payment.payment_results.length}`
          : ""
      }`,
      publicKey: environment.wompyKey,
      // redirectUrl: "http://localhost:8080/api/v2/payments/callback", // Opcional
      customerData: {
        // Opcional
        // email: "lola@gmail.com",
        // fullName: "Lola Flores",
        // phoneNumber: "3040777777",
        // phoneNumberPrefix: "+57",
        // legalId: "123456789",
        // legalIdType: "CC",
      },
    };
    console.log(paymentConfig);
    var checkout = new window.WidgetCheckout(paymentConfig);
    checkout.open((result) => {
      var transaction = result.transaction;
      console.log("Transaction ID: ", transaction.id);
      console.log("Transaction object: ", transaction);
    });
  }
  static getPayments(contract_code, page = 1, size = 20) {
    const params = { contract_code, page, size };
    return get(URL.payment.list, params).then((data) => {
      data.data.items.forEach((item) => {
        try {
        } catch (e) {
          console.error(e);
        }
      });
      return data.data.items;
    });
  }

  static getPaymentUrl(id) {
    return post(setParamsToUrl(URL.payment.start, { id })).then((data) => {
      return data;
    });
  }
}
