// @flow
import React, {Component, Fragment} from 'react';
import PropertyService from '../../Services/PropertiesService';
import queryString from 'query-string';
import {List} from 'react-content-loader';
import {imageUrl} from '../../Utils/Config';
import Lightbox from 'react-image-lightbox';
import {formatPrice, icons, randomNum} from '../../Utils/Utilities';
import ModalComponent from '../Shared/ModalComponent';
import {Pannellum} from 'pannellum-react';
import {toast} from '../../Services/AlertService';
import SharedService from '../../Services/SharedService';
import Role from '../../Models/Role';
import LogoWhatsapp from '../../Assets/Images/whatsapp.png';

type state = {
  photoIndex: number,
  isOpen: boolean,
  modalOpen: boolean,
  info: any
};

class PropertyDetailComponent extends Component <{}, state> {
  constructor(props) {
    super(props);
    this.state = {
      photoIndex: 0,
      isOpen: false,
      modalOpen: false,
      info: {
        title: 'Por favor ingrese sus datos',
        body: {}
      },
      inputs: [
        {
          type: 'text',
          placeHolder: 'Nombre',
          name: 'name'
        },
        {
          type: 'number',
          placeHolder: 'Teléfono',
          name: 'phone'
        },
        {
          type: 'email',
          placeHolder: 'Correo Electrónico',
          name: 'email'
        }
      ]
    };

  }

  componentDidMount() {
    const {id} = queryString.parse(this.props.location.search);

    PropertyService.getProperty(id).then(res => {
      let employeesList = [];
      PropertyService.getEmployees(res.service_type === 1 ? Role.sales : Role.adviser).then(employees => {
        employeesList = employees.data.items || [];
        this.setState(previousState => {
          return {...previousState, property: res, employee: employeesList[randomNum(employeesList.length)]};
        });
      });
    });
  }

  sendInterestAlert = e => {
    e.preventDefault();
    const clone = Object.assign({}, this.state);
    if (clone.name && clone.phone) {
      const params = {name: clone.name, phone: clone.phone, email: clone.email};
      if (params.email) {
        if (!params.email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) {
          return toast('El correo electrónico es inválido', 'error');
        }
      }
      params.msg = 'Deseo que me contacten acerca de la propiedad con consecutivo: # ' + this.state.property.ID + ' cod: ' + this.state.property.CODE;
      SharedService.sendInterestAlert(params);
      this.setState({...this.state, modalOpen: false});
    } else {
      return toast('Ingrese los campos requeridos', 'error');
    }
  };

  handleChange = event => {
    let {value, name} = event.target;
    if (event.target.type === 'checkbox') {
      value = event.target.checked ? 'si' : 'no';
    }
    this.setState({[name]: value});
  };

  render() {
    const {photoIndex, property, isOpen, isOpenModal360, inputs, employee} = this.state;
    if (!property) {
      return <List/>;
    } else {
      property.details = property.details.filter(i => !['Administracion valor', 'Número telefónico', 'Tipo Inmueble', 'Nombre Urbanización'].includes(i.name));
    }

    let images = this.state.property.photos.reduce((photos, i) => {
      if (i.split('_').includes('s')) {
        const large = i.replace('_s', '_l');
        if (this.state.property.photos.includes(large)) {
          photos.push(`${imageUrl}${i}`);
        }
      }
      return photos;
    }, []);

    const images360 = property.photos.reduce((photos, i) => {
      if (i.split('_')[1] === '360') {
        photos.push(`${imageUrl}${i}`);
      }
      return photos;
    }, []);

    return (
      <Fragment>
        <div className="container mb-4">
          <div className="row pt-5">
            <div className="col-sm-2 col-md-2 pt-2 col-lg-2 d-flex align-items-center">
              <button className="btn btn-secondary d-flex"
                      onClick={this.props.history.goBack}>
                <i className="fa fa-arrow-left pb-1 pt-1 mr-0 mr-md-2"/><span className="d-sm-flex d-none" >Regresar</span>
              </button>

              <h4 className="d-block d-md-none ml-5">
                {property.neighborhood} - {property.city}
              </h4>

            </div>
            <div className="col-sm-6 col-md-6 pt-2">
              <h2 className="d-none d-sm-block">
                {property.neighborhood} - {property.city}
              </h2>
              <h4 className="text-center text-md-left">
                <b>Código:</b> #{property.id} - <b className="text-primary">${formatPrice(property.price)}</b>
              </h4>
            </div>
            <div className="col-sm-2 col-md-4 pt-2 d-flex justify-content-around align-items-center">
              <span className="py-2 px-4 border border-dark rounded">
                {property.area}mt<sup>2</sup>
              </span>
              <button onClick={() => this.setState({modalOpen: true})} className="btn btn-secondary btn-block mr-2 ml-2">
                Me interesa
              </button>
            </div>
          </div>
          <div className="row mb-4 pt-3">
            <div className="">
              {images.map(i => (
                <img className="p-1 clickable"
                     onClick={() => this.setState({isOpen: true, photoIndex: images.indexOf(i)})}
                     key={i + i.name}
                     width={100}
                     height={140} src={i} alt="..."/>))}
            </div>
            <div className="">
              {images360.map(i => (
                <img className="p-1 clickable"
                     key={i + i.name + '360'}
                     onClick={() => this.setState({isOpenModal360: true, photoIndex: images360.indexOf(i)})}
                     width={100}
                     height={140} src={i} alt="..."/>))}
            </div>
            <br/>
          </div>
          <div className="row mb-4 pt-3">
            <div className="col-md-8 card border bg-white rounded-left">
              <div className="card-body">
                <h5 className="">Comodidades: </h5>
                <div className="row pt-2">
                  {property.details.map(i => (
                    <div className="col-4 col-md-4 col-lg-3" key={i.name + 'card'}>
                      {i.name}: <br/>
                      <img src={icons[i.name]} height={14}
                           alt=""/> {i.type === 3 ? (i.value === '1' ? 'Si' : 'No') : i.value}
                    </div>
                  ))}
                </div>
                {
                  !!property.measurements.length &&
                  <Fragment>
                    <h5 className="mt-3">Medidas: </h5>
                    <div className="row">
                      {property.measurements.map(i => (
                        <div className="col-6 col-md-4 pt-2" key={i.id}>
                          {i.measure_label}: <br/>
                          {i.measure_value}
                        </div>
                      ))}
                    </div>
                  </Fragment>
                }
                {
                  !!property.obs &&
                  <Fragment>
                    <h5 className="mt-3">Observaciones: </h5>
                    <div className="row p-3">
                      {property.obs}
                    </div>
                  </Fragment>
                }
              </div>
            </div>
            <div className="col-md-4 card card-body">
              <ul className="list-group bg-white p-0 card border-0">
                <li className="list-group-item border-0 text-center py-1 text-ayu-size-md">Comuníquese con nuestro asesor</li>
                {(employee.image!==null&&employee.image!=="https://ayuraphotos.s3.amazonaws.com/employees/1037571770.jpg")&&<img className="p-1 align-self-center rounded-circle"
                     height={140} src={employee.image} alt="..."/>}
                <li className="list-group-item border-0 text-center text-ayu-size-md text-ayu-bold py-1">{employee.name}</li>
                <li className="list-group-item border-0 text-center py-1">
                  <a className="ml-2" href={'https://api.whatsapp.com/send?phone=57' + employee.phone} target="_blank"
                     rel="noopener noreferrer">
                    <img src={LogoWhatsapp} height="20px" alt="..."/>
                  </a>
                  <a href={'tel:+57' + employee.phone}>{employee.phone}</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        {isOpen && (
          <Lightbox reactModalStyle={{overlay: {'zIndex': 2000}}}
                    mainSrc={images[photoIndex].replace('_s', '_l')}
                    nextSrc={images[(photoIndex + 1) % images.length]}
                    prevSrc={images[(photoIndex + images.length - 1) % images.length]}
                    onCloseRequest={() => this.setState({isOpen: false})}

                    onMovePrevRequest={() =>
                      this.setState({
                        photoIndex: (photoIndex + images.length - 1) % images.length
                      })
                    }
                    onMoveNextRequest={() =>
                      this.setState({
                        photoIndex: (photoIndex + 1) % images.length
                      })
                    }
          />
        )}
        {isOpenModal360 && (
          <ModalComponent footer={<Fragment>
            <button type="button" className="btn btn-dark"
                    onClick={() => this.setState({...this.state, isOpenModal360: false})}>Cancel
            </button>
          </Fragment>} onClose={() => this.setState({...this.state, isOpenModal360: false})} body={<Fragment>
            <Pannellum
              width="100%"
              height="500px"
              image={images360[photoIndex]}
              pitch={10}
              yaw={180}
              hfov={110}
              autoLoad
            >
            </Pannellum>
          </Fragment>}
          />
        )}
        {this.state.modalOpen &&
        <ModalComponent header="Ingrese sus datos"
                        footer={<Fragment>
                          <button type="button" className="btn btn-primary"
                                  onClick={this.sendInterestAlert}>Enviar
                          </button>
                          <button type="button" className="btn btn-dark"
                                  onClick={() => this.setState({...this.state, modalOpen: false})}>Cancelar
                          </button>
                        </Fragment>}
                        body={inputs.map(i => (
                          <div className="" key={i.name + 'modal'}>
                            <label className="text-ayu-bold">
                              {i.placeHolder}
                            </label>
                            <input
                              className="form-control mb-3"
                              type={i.type}
                              placeholder={i.placeHolder}
                              name={i.name}
                              key={i.name}
                              value={this.state[i.name]}
                              onChange={this.handleChange}
                            />
                          </div>))}
                        onClose={() => this.setState({...this.state, modalOpen: false})}
                        id={this.state.property.id}
                        cod={this.state.property.code}/>
        }
      </Fragment>
    );
  }
}

export default PropertyDetailComponent;
