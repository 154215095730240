import React, {Component, Fragment} from 'react';
import {Collapse, Nav, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink} from 'reactstrap';
import {Link, NavLink as RRNavLink} from 'react-router-dom';
import SocialMediaIcons from './SocialMediaIconsComponent';
import Logo from '../../Assets/Images/ayura-logo@2x.png';
import HomeIcon from '../../Assets/Images/home-white.png';
import RegisterIcon from '../../Assets/Images/inmueble.png';
import LoginIcon from '../../Assets/Images/login.png';
import heart from '../../Assets/Images/heart-icon@2x.png';
import pse from '../../Assets/Images/pagos_seguros_en_linea.png';
import ModalComponent from './ModalComponent';
import LogoUser from '../../Assets/Images/user.png';
import FormComponent from './FormComponent/FormComponent';
import {loginTenant} from '../../Utils/Utilities';

type State = {
  isOpen: boolean
};

class NavbarComponent extends Component<{}, State> {
  state = {
    isOpen: false,
    isModalOpen: false
  };

  toggle = () => {
    this.setState(prev => ({
      isOpen: !prev.isOpen
    }));
  };

  render() {


    return <Fragment>


      <div>
        <Navbar className="pl-0 justify-content-end" color="dark" dark fixed="top" style={{zIndex: "1035"}} expand="sm" >


          <div className="d-none d-sm-flex align-items-center justify-content-center p-0 clickable container-pse-logo">
            <img src={pse} className="pse-logo" alt=""
                 onClick={() => this.setState(s => ({...s, isModalOpen: true}))}/>
          </div>
          <Nav className="flex-row" navbar>

            <NavItem className="d-flex d-sm-none align-items-center justify-content-center p-0 clickable"
                     activeClassName="active">
              <img className="mr-2" src={pse} height={40} alt=""
                   onClick={() => this.setState(s => ({...s, isModalOpen: true}))}/>
            </NavItem>

                <NavItem className="mr-1 mr-md-0">
                  <NavLink className="d-flex align-items-center justify-content-center" to="/registrar_inmueble"
                       activeClassName="active" tag={RRNavLink}>
                    <img className="mr-0 mr-md-2" width="18px" height="17px" src={RegisterIcon} alt="..."/>
                    <span>Registrar Inmueble</span>
                  </NavLink>
                </NavItem>
                <NavItem className="mr-1 mr-md-0">
                  <NavLink className="d-flex align-items-center justify-content-center" to="/consultar_solicitud"
                           activeClassName="active" tag={RRNavLink}>
                    <img className="mr-0 mr-md-2" src={HomeIcon} alt="..."/>
                    <span>Consultar Solicitud</span>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink className="d-flex align-items-center justify-content-center" to="/login_clients"
                           activeClassName="active" tag={RRNavLink}>
                    <img className="mr-0 mr-md-2" src={LoginIcon} alt="..."/>
                    <span>Ingreso clientes</span>
                  </NavLink>
                </NavItem>

              </Nav>
            </Navbar>
          </div>


      <Navbar className="ayura-main-navbar" dark fixed="top" expand="md">
        <NavbarBrand tag={Link} to="/">
          <div className="pt-md-5">
            <img src={Logo} className="ayura-logo" alt="ayura logo"/>

            {/*Span Anterior*/}
            {/*<span className="text-ayu-size-md ayura-slogan">*/}
            {/*  Estamos en el <img height='22px' src={heart} alt="Corazón"/> de Envigado*/}
            {/*</span>*/}

            <span className="text-ayu-size-md ayura-slogan d-md-none d-lg-inline-block">
              Estamos en el <img height='22px' src={heart} alt="Corazón"/> de Envigado
            </span>
          </div>
        </NavbarBrand>

         {/*added to each component an onClick = {this.toogle} to be more optimal in the views.*/}

        <NavbarToggler onClick={this.toggle}/>
        <Collapse isOpen={this.state.isOpen} className="bg-primary" navbar>
          <Nav className="ml-auto d-flex align-items-center" navbar>

            {/*----------pse button in lg -----*/}

                {/*<NavItem className="d-none d-lg-flex  text-ayu-size-md clickable img-pse-lg"*/}
                {/*         activeClassName="active">*/}
                {/*  <img className="mr-2" src={pse} height={85} alt=""*/}
                {/*       onClick={() => this.setState(s => ({...s, isModalOpen: true}))}/>*/}
                {/*</NavItem>*/}

            {/*----------pse button in md -----*/}

                {/*<NavItem className="d-none d-md-flex d-lg-none text-ayu-size-md clickable img-pse-md"*/}
                {/*         activeClassName="active">*/}
                {/*  <img className="mr-2" src={pse} height={70} alt=""*/}
                {/*       onClick={() => this.setState(s => ({...s, isModalOpen: true}))}/>*/}
                {/*</NavItem>*/}


            <NavItem onClick={this.toggle} className="text-ayu-size-md">
              <NavLink to="/contactanos" activeClassName="active" tag={RRNavLink}>
                Contáctanos
              </NavLink>
            </NavItem>
            <NavItem onClick={this.toggle} className="text-ayu-size-md">
              <NavLink to="/servicios" activeClassName="active" tag={RRNavLink}>
                Servicios
              </NavLink>
            </NavItem>
            <NavItem onClick={this.toggle} className="text-ayu-size-md">
              <NavLink to="/requisitos" activeClassName="active" tag={RRNavLink}>
                Requisitos
              </NavLink>
            </NavItem>
            <NavItem onClick={this.toggle} className="text-ayu-size-md">
              <NavLink to="/sobre_ayura" activeClassName="active" tag={RRNavLink}>
                Sobre Ayurá
              </NavLink>
            </NavItem>
            <NavItem onClick={this.toggle} className="text-ayu-size-md">
              <NavLink to="/valores" activeClassName="active" tag={RRNavLink}>
                Nuestros Valores
              </NavLink>
            </NavItem>
            <NavItem onClick={this.toggle} className="my-3 my-md-0">
              <SocialMediaIcons/>
            </NavItem>
          </Nav>
        </Collapse>
      </Navbar>
      {this.state.isModalOpen &&
      <ModalComponent size="sm" header="Inicie sesión" onClose={() => this.setState(s => ({...s, isModalOpen: false}))}
                      body={
                        <div className="d-flex justify-content-center align-content-center">
                          <FormComponent
                            title="Clientes"
                            logo={LogoUser}
                            email={false}
                            click={loginTenant}
                          />
                        </div>}
      />}

    </Fragment>;
  }
}

export default NavbarComponent;
