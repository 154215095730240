import React, { Component } from 'react';
import { ReactComponent as LogoFacebook } from '../../Assets/Images/facebook-copy.svg';
import { ReactComponent as LogoTwitter } from '../../Assets/Images/twitter-copy.svg';
import { ReactComponent as LogoInstagram } from '../../Assets/Images/instagram-copy.svg';
import LogoWhatsapp from '../../Assets/Images/whatsapp.png';
class SocialMediaIcons extends Component <{},{}>{
  render() {
    return (
      <div className="logos flex-wrap text-center">
        <a href = "https://api.whatsapp.com/send?phone=573106062560" target="_blank" rel="noopener noreferrer"><img src={LogoWhatsapp} alt="..." /></a>
        <a href = "https://www.facebook.com/arrendamientos.ayura" target="_blank" rel="noopener noreferrer"> <LogoFacebook/></a>
        <a href = "https://www.instagram.com/arrendamientosayura/" target="_blank" rel="noopener noreferrer"><LogoInstagram/></a>
        <a href = "https://twitter.com/arrendaayura" target="_blank" rel="noopener noreferrer"> <LogoTwitter/></a>
      </div>
    );
  }
}

export default SocialMediaIcons;
