import React, {Component, Fragment} from 'react';
// import MainImage from '../../Assets/Images/DSC_3551.jpg';
import MainImage from '../../Assets/Images/serviciosReparaciones1.jpg';

class Repairs extends Component <{}, {}> {
    render() {
        return (
            <Fragment>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12  col-lg-6 mt-4 ">
                          <span className="col-sm">
                            <ul>
                              <li className="text-justify">Nos encargamos por los daños físicos y los faltantes causados por el arrendatario en su inmueble.</li>

                              <li className="text-justify">Contamos con personal capacitado en el campo para asesorarlo en todo lo que usted necesite referente a su inmueble. Electricidad, plomería, ebanistería, para que realicen los trabajos.</li>
                            </ul>
                          </span>
                        </div>
                        <div className="col-12  col-lg-6">
                            <img className="services-image img-fluid mb-5 " style={{width:"70%"}} src={MainImage} alt="not found"/>
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }
}

export default Repairs;
