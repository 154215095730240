import React, {Component, Fragment} from "react";
import "./LoadingComponent.scss"

class LoadingComponent extends Component {
    render() {
        let { show } = this.props;

        return (
            <Fragment>
                {
                    show && <div className="loader"/>
                }
            </Fragment>
        )
    }
}

export default LoadingComponent;