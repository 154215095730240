import {
    get
} from '../NetworkLayer/Index';
import {URL} from '../Utils/Config';

export default class PropertiesService {
    static loadCitys() {
        return get(URL.address.cities)
            .then(res => {
                return res.data.items;
            })
            .catch(error => {
                console.error(error);
            });
    }
}
