import React, {Component, Fragment} from 'react';
import SectionItems from '../../Utils/SectionItems';
import PropertiesList from '../PropertiesList/PropertiesListComponent';
import SearchLandingComponent from './SearchLanding/SearchLandingComponent';
import './LandingComponent.scss';
import {NavLink} from 'reactstrap';
import {NavLink as RRNavLink} from "react-router-dom";

class Landing extends Component<{}, {}>{
  render(){
    return (
        <Fragment>
          <div className = "landing-header">
            <div className = "row mx-0">
              <div className = "col-10 col-lg-4 col-sm-5 pt-5 pt-md-4 offset-1 offset-lg-7 offset-sm-6">

                <SearchLandingComponent/>
              </div>
            </div>
          </div>
          <div className = "d-flex justify-content-center mb-3 text-primary text-center">
            <h2>
              <i>
                No queremos ser la más grande sino la mejor
              </i>
            </h2>
          </div>
          <div className = "info-section row justify-content-center align-items-center">
            {SectionItems.map(i => (
                <Fragment key={i.id}>
                  {i.title === 'Políticas de seguridad' && (
                      <a href = {i.path}
                         download
                         target = {'_blank'}
                         key = {i.id}
                         className = "info-section-item col-lg-3 col-6">

                        <img src = {i.image} height = {60} className = "mb-3" alt = "..."/>
                        <h2 className = "mb-3">
                          <b> {i.title} </b>
                        </h2>
                        <p>{i.description}</p>
                      </a>
                  )}
                  {i.title !== 'Políticas de seguridad' && (
                      <NavLink to = {i.path}
                               key = {i.id}
                               className = "info-section-item col-lg-3 col-6"
                               activeClassName = "active" tag = {RRNavLink}>

                        <img src = {i.image} height = {60} className = "mb-3" alt = "..."/>
                        <h2 className = "mb-3">
                          <b> {i.title} </b>
                        </h2>
                        <p>{i.description}</p>
                      </NavLink>
                  )}
                </Fragment>
            ))}
          </div>


          <div className = "container px-3">
            <PropertiesList/>
          </div>
        </Fragment>
    );
  }
}

export default Landing;
