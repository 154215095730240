import React, {Component} from 'react';
import './Contracts.scss'


class ContractsComponent extends Component<{}, {}> {

    render() {
        const {contracts, select, contractKey} = this.props;
        return (
            <div className="container">
                <div className="row py-5">
                    <div className="col-12">
                        <h2 className="text-primary text-center text-ayu-bold">
                            Sus Contratos
                        </h2>
                    </div>
                </div>
                <div className="row text-center">
                    <div className="container">
                        <div className="row info-table p-1 info-table-header">
                            <div className="col-6 p-1">Contrato</div>
                            <div className="col-6 ">Cons. Propiedad</div>
                        </div>
                        {contracts.map(i => (
                            <div key={i.contract_id}
                                 onClick={() => select(i.contract_id, i.contract_code, i.tenant_name)}
                                 className={`row info-table info-table-item clickable ${i.contract_id === contractKey ? "item-active" : ""}`}>
                                <div className="col-6 ">
                                    {/*{!owner && <button className="btn btn-secondary btn-sm" onClick={repair}><i className="fa fa-wrench" /></button>} */}
                                    {i.contract_code}</div>
                                <div className="col-6 ">#{i.property_id}</div>
                            </div>
                        ))}

                    </div>
                </div>
            </div>
        );
    }
}


export default ContractsComponent;
