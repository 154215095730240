import React, {Component, Fragment} from 'react';
import PropertiesService from '../../Services/PropertiesService';
import {toast} from '../../Services/AlertService';

type state = {
  basicInfo: array<any>,
  propertyInfo: array<any>,
  additionalInfo: array<any>,
  params: any
};

class RegisterComponent extends Component<{}, state>{
  state = {
    info: {
      basicInfo: [
        {
          type: 'text',
          placeHolder: '*Nombre',
          name: 'name',
          required: true
        },
        {
          type: 'number',
          placeHolder: '*Teléfono',
          name: 'phone',
          required: true
        },
        {
          type: 'email',
          placeHolder: '*Correo Electrónico',
          name: 'email',
          required: true
        },
        {
          type: 'select',
          placeHolder: 'Tipo de Servicio',
          name: 'tipo_servicio',
          options: [
            {value: '', text: 'Tipo de Servicio'},
            {value: 'arrendamiento', text: 'Arrendamiento'},
            {value: 'venta', text: 'Venta'}
          ]
        },
        {
          type: 'number',
          placeHolder: 'Teléfono Propietario',
          name: 'telefono_propietario'
        },
        {
          type: 'number',
          placeHolder: 'Precio',
          name: 'precio_inmueble'
        },
        {
          type: 'text',
          placeHolder: 'Propietario',
          name: 'propietario'
        },
        {
          type: 'select',
          placeHolder: 'Tipo de Propiedad',
          name: 'tipo_propiedad',
          options: [
            {value: '', text: 'Tipo de Propiedad'},
            {value: 'apartaestudios', text: 'Apartaestudio'},
            {value: 'apartamento', text: 'Apartamento'},
            {value: 'casa', text: 'Casa'},
            {value: 'casalocal', text: 'Casa-Local'},
            {value: 'finca', text: 'Finca'},
            {value: 'local', text: 'Local'},
            {value: 'oficina', text: 'Oficina'},
            {value: 'oficinaconsultorio', text: 'Oficina-Consultorio'},
            {value: 'parqueadero', text: 'Parqueadero'},
            {value: 'terreno', text: 'Terreno'}
          ]
        },
        {
          type: 'number',
          placeHolder: 'Área (mts)',
          name: 'area'
        },
        {
          type: 'text',
          placeHolder: '*Dirección',
          name: 'direccion',
          required: true
        },
        {
          type: 'text',
          placeHolder: 'Barrio',
          name: 'barrio'
        },
        {
          type: 'select',
          placeHolder: 'Estrato',
          name: 'estrato',
          options: [
            {value: '', text: 'Estrato'},
            {value: 'e1', text: '1'},
            {value: 'e2', text: '2'},
            {value: 'e3', text: '3'},
            {value: 'e4', text: '4'},
            {value: 'e5', text: '5'},
            {value: 'e6', text: '6'},
            {value: 'e7', text: '7'}
          ]
        }
      ],
      propertyInfo: [
        {
          text: 'Agua Caliente',
          name: 'agua_caliente'
        },
        {
          text: 'Parqueadero',
          name: 'parqueadero'
        },
        {
          text: 'Sala Comedor',
          name: 'sala_comedor'
        },
        {
          text: 'Baño Cabina',
          name: 'bano_cabina'
        },
        {
          text: 'Zona ropas',
          name: 'zona_ropas'
        },
        {
          text: 'Cuarto de servicio',
          name: 'cuarto_servicio'
        },
        {
          text: 'Baño Servicio',
          name: 'bano_servicio'
        },
        {
          text: 'Cuarto útil',
          name: 'cuarto_util'
        },
        {
          text: 'Línea teléfono',
          name: 'linea_telefono'
        },
        {
          text: 'Biblioteca',
          name: 'biblioteca'
        },
        {
          text: 'Vestier',
          name: 'vestier'
        },
        {
          text: 'Balcón',
          name: 'balcon'
        },
        {
          text: 'Hall',
          name: 'hall'
        },
        {
          text: 'Terraza',
          name: 'terraza'
        },
        {
          text: 'Unidad Cerrada',
          name: 'unidad_cerrada'
        }
      ],
      additionalInfo: [
        {
          type: 'number',
          placeHolder: 'N. Alcobas',
          name: 'numero_alcobas'
        },
        {
          type: 'number',
          placeHolder: 'N. Closets',
          name: 'numero_closets'
        },
        {
          type: 'number',
          placeHolder: 'N. Baños',
          name: 'numero_banos'
        },
        {
          type: 'select',
          placeHolder: 'Cocina',
          name: 'cocina',
          options: [
            {value: '', text: 'Cocina'},
            {value: 0, text: 'Integral'},
            {value: 1, text: 'Cocineta Sencilla'},
            {value: 2, text: 'Semi-Integral'},
            {value: 3, text: 'Integral Mixta'},
            {value: 4, text: 'Sin Cocina'}
          ]
        },
        {
          type: 'select',
          placeHolder: 'Gas',
          name: 'gas',
          options: [
            {value: '', text: 'Gas'},
            {value: 'Red', text: 'Red'},
            {value: 'Pipeta', text: 'Pipeta'},
            {value: 'Ninguna', text: 'Ninguna'}
          ]
        },
        {
          type: 'number',
          placeHolder: 'N. Garajes',
          name: 'num_garajes'
        },
        {
          type: 'select',
          placeHolder: 'Piso',
          name: 'piso',
          options: [
            {value: '', text: 'Piso'},
            {value: 'Baldosa', text: 'Baldosa'},
            {value: 'Cerámica', text: 'Cerámica'},
            {value: 'Porcelanato', text: 'Porcelanato'},
            {value: 'Cemento', text: 'Cemento'},
            {value: 'Mármol', text: 'Mármol'}
          ]
        },
        {
          type: 'number',
          placeHolder: 'Nivel #',
          name: 'nivel'
        },
        {
          type: 'select',
          placeHolder: 'Tipo de Inmueble',
          name: 'tipo_inmueble',
          options: [
            {value: '', text: 'Tipo de Inmueble'},
            {value: 'ininmueble', text: 'Independiente'},
            {value: 'induplex', text: 'Duplex'},
            {value: 'inbifamiliar', text: 'Bifamiliar'},
            {value: 'inunifamiliar', text: 'Unifamiliar'},
            {value: 'intriplex', text: 'Triplex'}
          ]
        },
        {
          type: 'number',
          placeHolder: 'N. Patios',
          name: 'num_patios'
        }
      ],
      closedUnitInfo: [
        {
          type: 'text',
          placeHolder: 'Nombre Unidad',
          name: 'nombre_unidad'
        },
        {
          type: 'number',
          placeHolder: 'Valor Admon',
          name: 'valor_admon'
        },
        {
          type: 'checkbox',
          placeHolder: 'Juegos',
          name: 'juegos'
        },
        {
          type: 'checkbox',
          placeHolder: 'Salón Social',
          name: 'salon_social'
        },
        {
          type: 'checkbox',
          placeHolder: 'Ascensor',
          name: 'ascensor'
        },
        {
          type: 'checkbox',
          placeHolder: 'Piscina',
          name: 'piscina'
        },
        {
          type: 'checkbox',
          placeHolder: 'Portería 24hrs',
          name: 'porteria24h'
        }
      ],
    }
  };

  sendRegister = e => {
    e.preventDefault();
    const clone = Object.assign({}, this.state);
    if(clone.name && clone.phone && clone.email && clone.direccion){
      if(clone.email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)){
        const params = {name: clone.name, phone: clone.phone, email: clone.email};
        delete clone.info;
        delete clone.name;
        delete clone.phone;
        delete clone.email;
        delete clone.error;

        params.property_info = clone;
        PropertiesService.registerProperty(params).then(res => {
          if(res.success){
            toast(res.res.msg, 'success');
          } else{
            toast('El registro no pudo ser enviado', 'error');
          }
        }).catch(() => {
          toast('El registro no pudo ser enviado', 'error');
        });
      } else{
        this.setState({error: true});
        toast('Ingrese un correo electrónico válido', 'error');
      }
    } else{
      this.setState({error: true});
      toast('Ingrese los campos requeridos', 'error');
    }
  };

  showDangerBorder = (i) => {
    if(this.state[i.name] || !i.required){
      return false
    } else {
      return i.required && (this.state[i.name] !== undefined || this.state.error)
    }
  };

  handleChange = event => {
    let {value, name} = event.target;
    if(event.target.type === 'checkbox'){
      value = event.target.checked ? 'si' : 'no';
    }
    this.setState({[name]: value});
  };

  render(){
    const {info, tipo_servicio} = this.state;
    return (
        <div className = "container px-5 bg-white">
          <form onSubmit = {this.sendRegister}>
            <div className = "row py-5">
              <div className = "col-12">
                <h2 className = "text-primary text-center text-ayu-bold">
                  Registro de Inmueble
                </h2>
              </div>
            </div>
            <div className = "row mb-4">
              {info.basicInfo.map(i => (
                  <div className = "col-6 col-md-4">
                    <label className = "text-ayu-bold">
                      {i.placeHolder}
                    </label>
                    {i.type !== 'select' && (
                        <input
                            className = {`form-control mb-3 ${this.showDangerBorder(i) ? 'border border-danger' : ''}`}
                            type = {i.type}
                            placeholder = {i.placeHolder}
                            name = {i.name}
                            key = {i.name}
                            value = {this.state[i.name]}
                            onChange = {this.handleChange}
                        />
                    )}
                    {i.type === 'select' && (
                        <select
                            className = "form-control border-0 py-0"
                            name = {i.name}
                            key = {i.name}
                            value = {this.state[i.name]}
                            onChange = {this.handleChange}>
                          {i.options.map(opt => (
                              <option value = {opt.value}>
                                {opt.text}
                              </option>
                          ))}
                        </select>
                    )}
                  </div>
              ))}
            </div>
            <div className = "row p-2">
              <div className = "d-flex">
                <h4 className = "text-primary text-ayu-bold justify-content-start">
                  Características del inmueble
                </h4>
              </div>
            </div>
            <div className = "row p-3">
              {info.propertyInfo.map(i => (
                  <div className = "col-4 col-md-3 form-check pb-2">
                    <input
                        className = "form-check-input mb-3"
                        type = "checkbox"
                        name = {i.name}
                        key = {i.name}
                        id = {i.name}
                        value = {this.state[i.name]}
                        onChange = {this.handleChange}
                    />
                    <label className = "text-ayu-bold form-check-label" htmlFor={i.name}>
                      {i.text}
                    </label>
                  </div>
              ))}
            </div>
            {this.state.unidad_cerrada === 'si' && (
                <div className = "row mb-4 p-2 border border-secondary rounded">
                  <div className = "col-12 pb-2">
                    <h5 className = "text-primary text-ayu-bold">
                      Características de unidad cerrada
                    </h5>
                  </div>
                  {info.closedUnitInfo.map(i => (
                      i.type === 'checkbox' ? (
                              <div className = "col-4 col-md-3 form-check pb-2">
                                <input
                                    className = "form-check-input mb-3"
                                    type = "checkbox"
                                    name = {i.name}
                                    key = {i.name}
                                    id = {i.name}
                                    value = {this.state[i.name]}
                                    onChange = {this.handleChange}
                                />
                                <label className = "text-ayu-bold form-check-label" htmlFor={i.name}>
                                  {i.placeHolder}
                                </label>
                              </div>) :
                          (
                              <div className = "col-6">
                                {((i.name === 'valor_admon' && tipo_servicio === 'venta') || i.name !== 'valor_admon') && (
                                    <Fragment>
                                      <label className = "text-ayu-bold">
                                        {i.placeHolder}
                                      </label>
                                      <input
                                          className = "form-control mb-3"
                                          type = {i.type}
                                          placeholder = {i.placeHolder}
                                          name = {i.name}
                                          key = {i.name}
                                          value = {this.state[i.name]}
                                          onChange = {this.handleChange}
                                      /></Fragment>)}
                              </div>

                          )))
                  }
                </div>
            )}
            <div className = "row mb-4">
              {info.additionalInfo.map(i => (
                  <div className = "col-6 col-md-4">
                    <label className = "text-ayu-bold">
                      {i.placeHolder}
                    </label>
                    {i.type !== 'select' && (
                        <input
                            className = "form-control mb-3"
                            type = {i.type}
                            name = {i.name}
                            key = {i.name}
                            placeholder = {i.placeHolder}
                            onChange = {this.handleChange}
                        />
                    )}
                    {i.type === 'select' && (
                        <select
                            className = "form-control border-0"
                            name = {i.name}
                            key = {i.name}
                            value = {this.state[i.name]}
                            onChange = {this.handleChange}>
                          {i.options.map(opt => (
                              <option value = {opt.value}>
                                {opt.text}
                              </option>
                          ))}
                        </select>
                    )}
                  </div>
              ))}
            </div>
            <div className = "row mb-5 p-3">
              <button type = "submit" className = "btn btn-secondary px-5">Enviar</button>
            </div>
          </form>
        </div>
    );
  }
}

export default RegisterComponent;
