import React, {Component, Fragment} from 'react';
import MainImage1 from '../../Assets/Images/leasingservice1.jpg';

class Leasing extends Component <{},{}>{
  render(){
    return (
        <Fragment>
          <div className = "container-fluid">
              <div className = "row justify-content-center">
                  <div className = "col-4">
                      <img className = "services-image img-fluid mb-5" src = {MainImage1} alt = "not found"/>
                  </div>
              </div>
            <div className = "row">
              <div className = "col-12 ">
          <span className = "col-sm">
            <p className="text-ayu-size-sm">
              <b>Arrendamos sus inmuebles en tiempo record</b>, realizamos el pago de servicios públicos,
              impuestos, obligaciones y administración.
            </p>
            <p className="text-ayu-size-sm">
              <b>Contamos con el mejor equipo humano</b> especializado en la promoción de inmuebles, donde
              establecemos los planes de comercialización.
            </p>
            <p className="text-ayu-size-sm">
              <b>Velamos por la buena conservación y prevención</b> de los inmuebles realizando inventario
              antes y después de arrendarlos.
            </p>
            <p className="text-ayu-size-sm">
              <b>Además en nuestro sitio web promocionamos sus inmuebles</b> con toma fotográfica.
              Consígnenos ya su inmueble, estaremos orgullosos de servirle.
            </p>
          </span>
              </div>
            </div>
          </div>

        </Fragment>
    );
  }
}

export default Leasing;
