const DEV = {
    production: false,
    wompyKey: "pub_test_0Kf4heyAsa5h77E9NjkOtxOyFIMs2Jtd",
    baseUrl: 'http://localhost:8080/api/v2'
};

const QA = {
    production: false,
    wompyKey: "pub_test_0Kf4heyAsa5h77E9NjkOtxOyFIMs2Jtd",
    baseUrl: 'http://18.144.6.14/api/v2'
};

const PROD = {
    production: true,
    wompyKey: "pub_prod_VZTtPaINt1PE37zAbDRyotXBmgcqLosx",
    baseUrl: 'https://arrendamientosayura.com/api/v2'
};

let env;

switch (process.env.REACT_APP_ENVIROMENT) {
    case 'DEV':
        env = DEV;
        break;
    case 'QA':
        env = QA;
        break;
    case 'PROD':
        env = PROD;
        break;
    default:
        env = DEV;
}

export default env;
