import {get} from '../NetworkLayer/Index';
import {URL} from '../Utils/Config';

export default class ContactSummaryService {

  static loadContactTypes() {
    return get(URL.contactRequest.stats.type)
      .then(res => {
        return res.data.items;
      })
      .catch(error => {
        console.error(error);
      });
  }

  static loadContactSales() {
    return get(URL.contactRequest.stats.sales)
      .then(res => {
        return res.data.items;
      })
      .catch(error => {
        console.error(error);
      });
  }
}
