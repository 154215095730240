import {
    LOAD_CITIES
} from '../ActionTypes';
import {
    sortArray
} from '../../Utils/Utilities';

const initialState = {
    cities: []
};

export default function AddressReducer(state: any = initialState, action: {type: string, payload: any}) {
    switch (action.type) {
        case LOAD_CITIES:
            return Object.assign({}, state, {
                cities: sortArray(action.payload, 'name')
            });
        default:
            return state;
    }
}