// @flow
import React, { Component } from 'react';

import PropertiesList from '../PropertiesList/PropertiesListComponent';
import SearchLandingComponent from '../Landing/SearchLanding/SearchLandingComponent';

class Search extends Component<{}, {}> {
    render() {
        return (
            <div className="pt-5">
                <div className="mb-5">
                    <SearchLandingComponent/>
                </div>
                <div className="container px-3">
                    <PropertiesList />
                </div>
            </div>
        );
    }
}

export default Search;
