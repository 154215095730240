import React, {Component} from 'react';
import MainImage from '../../Assets/Images/serviciosVentas.jpg';

class Sales extends Component <{}, {}>{
  render(){
    return (
        <div className = "container-fluid">
          <div className = "row">
            <div className = "col-12  col-lg-6">
          <span className = "col-sm">
            <p className = "text-ayu-size-sm">Ofrecemos a los propietarios un plan de comercialización para la venta de sus inmuebles en el menor tiempo posible, igualmente ofrecemos a nuestros compradores la asesoría inmobiliaria necesaria según su necesidad.</p>
            <p>La estrategia de Mercadeo:</p>
            <ul>
              <li className = "text-justify"> Toma fotográfica para su publicación en nuestra página web: www.arrendamientosayura.com, Portales de búsqueda de inmuebles y publicación en periódicos.</li>
              <li className = "text-justify">Contamos con la asesoría de la consecución de créditos hipotecarios.</li>
              <li className = "text-justify">Trabajamos para establecer relaciones entre propietarios y compradores, relaciones de negociación claras y justas, sabemos lo que significa para usted conservar su patrimonio, porque su bienestar es nuestro compromiso.</li>
              <li className = "text-justify">Con nosotros su negocio es seguro y eficaz para propietarios y compradores.</li>
              <li className = "text-justify">Asesoramos en la elaboración de promesas de compraventa y escrituras.</li>
            </ul>
          </span>
            </div>
            <div className = "col-12  col-lg-6">
              <img className = "services-image img-fluid mb-5 w-75" src = {MainImage} alt = "not found"/>
            </div>
          </div>
        </div>
    );
  }
}

export default Sales;
