import {
  applyMiddleware,
  combineReducers,
  createStore,
    compose
} from 'redux';

import ReduxPromise from 'redux-promise';
import PropertiesReducer from './Reducers/PropertiesReducer';
import AddressReducer from './Reducers/AddressReducer';
import InformationReducer from './Reducers/InformationReducer';
/*
  Store
  Redux apps have a single store which takes
  1. All Reducers which we combined into `rootReducer`
  2. An optional starting state - similar to React's getInitialState
*/

const rootReducer = combineReducers({
  PropertiesReducer,
  AddressReducer,
  InformationReducer
});

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const createStoreWithMiddleware = applyMiddleware(ReduxPromise)(createStore);

export default createStoreWithMiddleware(rootReducer, composeEnhancers());