import env from "../environment";

export const baseUrl: string = env.baseUrl || '';
export const imageUrl: string = `${baseUrl}/property/photo/get?key=`;
export const URL: any = {
  address: {
    cities: '/options/cities'
  },
  user: {
    login: '/session/login',
    emailChange: '/user/email/change'
  },
  contactRequest: {
    add: '/contact-request/add',
    stats: {
      type: '/contact-request/stats/type',
      sales: '/contact-request/stats/sales'
    },
  },
  contracts: {
    search: '/contracts/search'
  },
  propertyRequest:{
    list: '/prop-request/list',
    comment: {
      list: '/prop-request/comments',
      add: '/prop-request/comments/add'
    }
  },
  prices: {
    rent: '/options/prices/rent',
    sell: '/options/prices/sell'
  },
  property: {
    search: '/property/search',
    photos: '/property/photo/list',
    register: '/email/property/register',
    measurements: '/measurement/list'
  },
  payment: {
    list: '/payments/list',
    start: '/payments/$id$/start'
  },
  content: {
    search: '/content/searchByEntity',
    upload: '/content/upload'
  },
  employees: 'employee/list',
  repair: '/repair/create',
  registerPayment: '/email/payment',
  publicServices: '/email/publicServices',
  types: '/options/types',
  areas: 'options/areas',
  repairCodes: '/repair/codes',
  repairs: '/repair/list',
  repairImages: '/repair/images/',
};
