import React, { Component, Fragment } from 'react';
import Routes from '../../Routes/Routes';
import { Route, Switch } from 'react-router-dom';
import NavbarComponent from '../Shared/NavbarComponent';
import Footer from '../Shared/FooterComponent';
import Landing from '../Landing/LandingComponent';


class DashboardLandingComponent extends Component<{},{}> {
    render() {

        return (
            <Fragment>
                <NavbarComponent />

                <div className="main-container">
                    <Switch>
                        <Route exact path="/" component={Landing} />
                        {
                            Routes.map(i => (
                            <Route
                                key={i.id}
                                path={i.path}
                                component={i.component}
                            />
                        ))}
                    </Switch>
                </div>

                <Footer />

                {/* <FAB /> */}
            </Fragment>
        );
    }
}

export default DashboardLandingComponent;