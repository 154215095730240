import React, {Component} from 'react';
import {Nav, Navbar, NavbarBrand, NavItem, NavLink} from "reactstrap";
import Logo from "../../Assets/Images/ayura-logo@2x.png";

export default class LoggedNavbarComponent extends Component{

  componentDidMount(){
  }

  render(){
    return (
        <Navbar
            style = {{height: '54px'}}
            color = "primary"
            dark
            fixed = "top"
            expand = "sm">
          <NavbarBrand>
            <img height = "46px" src = {Logo} alt = "ayura logo"/>
          </NavbarBrand>
          <Nav className = "ml-auto flex-row" navbar>
            <NavItem className = "mr-2 mr-md-0 clickable">
              <NavLink className = "d-flex align-items-center justify-content-center text-white"
                        onClick = {()=>this.props.action()}>
                <i className = "fa fa-sign-out"/> Salir
              </NavLink>
            </NavItem>
          </Nav>
        </Navbar>
    );
  }
}
