import React, {Component} from 'react';
import './ContactUs.scss';
import Map from '../Map/MapComponent';
import SocialMediaIconsComponent from '../Shared/SocialMediaIconsComponent';
import SharedService from '../../Services/SharedService';
import LogoWhatsapp from '../../Assets/Images/whatsapp.png';
import {toast} from '../../Services/AlertService';

type state = {
  name: string,
  email: string,
  phone: number,
  msg: string,
  type: string,
  contacts: Array<any>
}

class ContactUs extends Component<{}, state>{
  state = {
    name: '',
    email: '',
    phone: '',
    msg: '',
    type: "WEB",
    contacts: [
      {
        name: "Reparaciones y Prediales",
        email: "reparacionesyprediales@arrendamientosayura.com",
        phone: "300 6882875"
      },
      {
        name: "Servicio al cliente y contratos",
        email: "contratosypropietarios@arrendamientosayura.com",
        phone: "311 5359292"
      },
      {
        name: "Servicio al cliente y Entregas finales",
        email: "inventariosfinales@arrendamientosayura.com",
        phone: "315 3821874"
      },
      {
        name: "Servicios publicos y administraciones",
        email: "servipublicosyadmin@arrendamientosayura.com",
        phone: "300 4115712"
      },
      {
        name: "Consignacion de Propiedades",
        email: "mercadeo@arrendamientosayura.com",
        phone: "310 5037186"
      },
      {
        name: "Servicio al cliente y Cartera",
        email: "auxiliarcartera1@arrendamientosayura.com",
        phone: "312 7233936"
      },
      {
        name: "Servicio al cliente y Cartera",
        email: "auxiliarcartera2@arrendamientosayura.com",
        phone: "321 8179266"
      },
      {
        name: "Servicio al cliente y Asesor Juridico",
        email: "juridica@arrendamientosayura.com",
        phone: "313 7200772"
      },
      {
        name: "Servicio al cliente y Contabilidad",
        email: "contabilidad@arrendamientosayura.com"

      },
      {
        name: "Servicio al cliente y Caja",
        email: "caja@arrendamientosayura.com",
        phone: "301 2667350"
      }
    ]
  };

  handleChange = event => {
    const {value, name} = event.target;
    this.setState({[name]: value});
  };

  send = e => {
    e.preventDefault();
    const {name, email, phone, msg, type} = this.state;
    if(!name || !email || !phone || !msg){
      toast('Por favor ingrese todos los campos', 'error');
    } else{
      SharedService.sendContactUs({name, email, phone, msg, type}).then(res => {
        if(res.success){
          toast(res.res.msg, 'success');
        } else{
          toast(res.res.msg, 'error');
        }
      });
    }
  };

  render(){
    return (<div className = "container">
          <div className = "row pt-5">
            <div className = "col-md-8 col-lg-5  pl-5 mb-5">
              <form onSubmit = {this.send}>
                <input name = "name"
                       value = {this.state.name}
                       onChange = {this.handleChange}
                       className = "form-control mb-3 contact-input-small"
                       type = "text"
                       placeholder = "Nombre"/>
                <input name = "email"
                       value = {this.state.email}
                       onChange = {this.handleChange}
                       className = "form-control mb-3 contact-input-small"
                       type = "text"
                       placeholder = "Correo electrónico"/>
                <input name = "phone"
                       value = {this.state.phone}
                       onChange = {this.handleChange}
                       className = "form-control mb-3 contact-input-small"
                       type = "number"
                       placeholder = "Teléfono"/>
                <textarea name = "msg"
                          value = {this.state.msg}
                          onChange = {this.handleChange}
                          className = "form-control mb-3 contact-input-large"
                          placeholder = "Mensaje" rows = "3"/>
                <div className = "form-group row d-flex justify-content-between align-items-center px-3">
                  <button className = "btn btn-secondary send-button" type = "submit">
                    Enviar
                  </button>
                  <SocialMediaIconsComponent/>
                </div>
              </form>
            </div>
            <div className = "col-lg-1 d-none d-lg-block line"/>
            <div className = "col-lg-6 col-md-12 pl-5">
              <h3 className = "text-primary font-weight-bold">
                Contáctanos
              </h3>
              <div className = "contact-info pt-2">
                <ul className = "list-unstyled">
                  <li>Calle 37 Sur No. 41 - 26 Segundo Piso</li>
                  <li className = "mb-3">Envigado - Antioquia</li>
                  {/*<li>PBX: <a href = "tel:+5742766856">276 68 56</a> - <a href = "tel:+5742700561">270 05 61</a></li>*/}
                  <li>PBX: <a href = "tel:+572766856">276 68 56</a></li>
                  {/*<li> Fax: <a href = "tel:+5743790332">379 03 32</a></li>*/}
                  <li >Ventas:
                    <a className="ml-2" href = "https://api.whatsapp.com/send?phone=573218845943" target = "_blank"
                       rel = "noopener noreferrer">
                      <img src = {LogoWhatsapp} height = "20px" alt = "..."/>
                    </a>
                    <a href = "tel:+573218845943">321 8845943</a>
                  </li>
                  <li className = "mb-3">Arrendamientos:
                    <a className="ml-2" href = "https://api.whatsapp.com/send?phone=573106062560" target = "_blank"
                       rel = "noopener noreferrer">
                      <img src = {LogoWhatsapp} height = "20px" alt = "..."/>
                    </a>
                    <a href = "tel:+573015501518">301 5501518</a> -
                    <a href = "tel:+572766856">276 68 56</a>
                  </li>

                  {this.state.contacts.map(i => (
                          <div className = "mb-3" key = {i.email}>
                            <li><b>{i.name}</b></li>
                            <li>
                              <i className = "fa fa-envelope-o mail-icon"/>
                              <a className = "ml-2" href = {`mailto:${i.email}`}>{i.email}</a>
                            </li>
                            {i.phone&&
                              <li>
                                <a href = {`https://api.whatsapp.com/send?phone=57${i.phone.split(' ').join('')}`}
                                   target = "_blank" rel = "noopener noreferrer">
                                  <img src = {LogoWhatsapp} height = "20px" alt = "..."/>
                                </a>
                                <a className = "ml-2" href = {`tel:+57${i.phone.replace(/\s+/g, '')}`}>{i.phone}</a>
                              </li>
                            }

                          </div>
                      )
                  )}
                </ul>
              </div>
            </div>
          </div>
          <div className = "row py-5">
            <Map/>
          </div>
        </div>
    );
  }
}

export default ContactUs;
