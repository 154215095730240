import Axios from 'axios';
import {
  baseUrl
} from '../Utils/Config';
import {SbxCoreService, SbxSessionService} from 'sbx-axios';

const sbxCoreService = new SbxCoreService();
const sbxSessionService = new SbxSessionService(sbxCoreService);
sbxSessionService.initialize(243, '5da387f2-d1f5-420f-80b0-cfdf657866e8');

export {sbxSessionService, sbxCoreService};

const token = localStorage.getItem('token');
const Authorization = token ? `Bearer ${token}` : '';

const http = Axios.create({
  baseURL: baseUrl,
  headers: { Authorization }
});

type MyType = {
  data: any
}

type Error = {
  error: string
}
type Response = MyType & Error

export function get(
  url: string,
  params: any
): Promise < Response > {
  return http.get(url, {
    params
  }).catch(reason => {
    console.error(reason.message);
  });
}

export function post(url: string, params): Promise < Response > {
  return http.post(url,params)
  .then(res => res.data)
  .catch(reason => {
    console.error(reason.message);
  });
}

export function setToken(token: string) {
  http.interceptors.request.use(function (config) {
    config.headers.Authorization =  token ? `Bearer ${token}` : '';
    return config;
  });
}