import PropertiesService from "../Services/PropertiesService";
import AddressService from "../Services/AddressService";
import ContractService from "../Services/ContractService";
import RequestsService from "../Services/RequestsService";
import PaymentService from "../Services/PaymentService";

export const LOAD_PROPERTIES = "LOAD_PROPERTIES";
export const SHOW_LOADING = "SHOW_LOADING";
export const LOAD_PROPERTIES_TYPES = "LOAD_PROPERTIES_TYPES";
export const LOAD_PROPERTIES_RENT_PRICES = "LOAD_PROPERTIES_RENT_PRICES";
export const LOAD_PROPERTIES_SELL_PRICES = "LOAD_PROPERTIES_SELL_PRICES";
export const LOAD_PROPERTIES_AREAS = "LOAD_PROPERTIES_AREAS";
export const LOAD_CITIES = "LOAD_CITIES";
export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const LOADING_PROPERTIES = "LOADING_PROPERTIES";
export const GET_PROPERTY = "GET_PROPERTY";
export const LOAD_PAYMENTS = "LOAD_PAYMENTS";
export const LOAD_CONTRACTS = "LOAD_CONTRACTS";
export const LOAD_DOCUMENTS = "LOAD_DOCUMENTS";
export const LOAD_REQUEST_INFO = "LOAD_REQUEST_INFO";
export const LOAD_REQUEST_DOCUMENTS = "LOAD_REQUEST_DOCUMENTS";
export const LOAD_REQUEST_COMMENTS = "LOAD_REQUEST_COMMENTS";
export const CHANGE_SEARCH_PAGES = "CHANGE_SEARCH_PAGES";
export const CHANGE_CURRENT_REQUEST = "CHANGE_CURRENT_REQUEST";

export const loadProperties = (param: any, page) => ({
    type: LOAD_PROPERTIES,
    payload: PropertiesService.loadProperties(param).then()
});
export const loadFinish = () => ({
  type: LOADING_PROPERTIES,
  payload: {},
});
export const setCurrentUser = (user) => ({
  type: SET_CURRENT_USER,
  payload: user,
});
export const loadPropertiesTypes = () => ({
    type: LOAD_PROPERTIES_TYPES,
    payload: PropertiesService.loadPropertiesTypes(),
});
export const loadPropertiesRentPrices = () => ({
    type: LOAD_PROPERTIES_RENT_PRICES,
    payload: PropertiesService.loadPropertiesRentPrices(),
});
export const loadPropertiesSellPrices = () => ({
    type: LOAD_PROPERTIES_SELL_PRICES,
    payload: PropertiesService.loadPropertiesSellPrices(),
});
export const loadPropertiesAreas = () => ({
    type: LOAD_PROPERTIES_AREAS,
    payload: PropertiesService.loadPropertiesAreas(),
});
export const loadCities = () => ({
    type: LOAD_CITIES,
    payload: AddressService.loadCitys(),
});

export const getProperty = (id: number) => ({
    type: GET_PROPERTY,
    payload: PropertiesService.getProperty(id),
});
export const loadContracts = (id: string, owner?: boolean, page = 0) => ({
    type: LOAD_CONTRACTS,
    payload: ContractService.getContracts(id, owner, page),
});

export const loadPayments = (code: string, user, page?: number) => ({
    type: LOAD_PAYMENTS,
    payload: PaymentService.getPayments(code, page),
});

export const showLoading = (payload: boolean) => ({
    type: SHOW_LOADING,
    payload,
});

export const loadDocuments = (
  id: string,
  docTypeId?: number,
  page?: number,
  contract?: String,
  parentId?: number,
  spaceKey?: String,
  invRequest?: String
) => ({
    type: LOAD_DOCUMENTS,
    payload: ContractService.getDocs(id, docTypeId, page, contract, parentId, spaceKey, invRequest),
});

export const loadReqInfo = (id: string) => ({
    type: LOAD_REQUEST_INFO,
    payload: RequestsService.getInfo(id),
});

export const loadReqDocuments = (
  id: string,
  reqId?: number,
  docTypeId?: number,
  size?: number
) => ({
    type: LOAD_REQUEST_DOCUMENTS,
    payload: RequestsService.getRequestDocs(id, reqId, docTypeId),
});

export const loadReqComments = (reqId?: number) => ({
    type: LOAD_REQUEST_COMMENTS,
    payload: RequestsService.getRequestComments(reqId),
});

export const changeCurrentRequest = (index) => ({
    type: CHANGE_CURRENT_REQUEST,
    payload: index,
});
