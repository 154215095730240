// @flow
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {
  loadCities,
  loadProperties,
  loadPropertiesAreas,
  loadPropertiesRentPrices,
  loadPropertiesSellPrices,
  loadPropertiesTypes
} from '../../../Redux/ActionTypes';
import {withRouter} from 'react-router-dom';
import queryString from 'query-string';
import { formatPrice } from '../../../Utils/Utilities'

type state = {
  type: string,
  city: string,
  price: string,
  area: string,
  id: string,
  target: string,
  showMore: boolean
};

type Props = {
  loadPropertiesTypes: void,
  loadPropertiesRentPrices: void,
  loadPropertiesSellPrices: void,
  loadCities: void,
  loadPropertiesAreas: void,
  types: Array<any>,
  rentPrices: Array<any>,
  sellPrices: Array<any>,
  areas: Array<any>,
  sellPrices: Array<any>,
  cities: Array<any>
};

class SearchLandingComponent extends Component<Props, state>{
  state = {
    type: '',
    city: '',
    price: '',
    area: '',
    id: '',
    target: null,
    showMore: false
  };

  Toggle = e => {
    e.preventDefault();
    this.setState({showMore: !this.state.showMore});
    console.log(this.state.showMore);
  };

  componentDidMount(){
    this.props.loadPropertiesTypes();
    this.props.loadPropertiesRentPrices();
    this.props.loadPropertiesSellPrices();
    this.props.loadCities();
    this.props.loadPropertiesAreas();
    if(this.props.location){
      const queryParams = queryString.parse(this.props.location.search);
      Object.keys(queryParams).forEach(i => {
        if(!queryParams[i]){
          return;
        }
        this.setState(prev => {
          return {
            ...prev,
            [i]: queryParams[i]
          };
        });
      });
    }
  }

  search = e => {
    e.preventDefault();
    const {showMore, ...params} = this.state;
    Object.keys(params).forEach(key => {
      if(params[key] === ''){
        delete params[key];
      }
    });
    let queryParam = '';
    Object.keys(params).forEach(i => {
      if((i === 'condo' || i === 'parking') && !showMore){
        return;
      }
      queryParam += `${i}=${params[i]}&`;
    });
    this.props.history.push({
      pathname: '/buscar',
      search: queryParam
    });
    this.props.loadProperties({...params, page: 1}).then(res => {
    });
  };
  handleChange = event => {
    const {value, name} = event.target;
    this.setState({[name]: value});
  };

  render(){
    const {showMore} = this.state;
    return (
        <div className = "card-search p-4 container">
          <form onSubmit = {this.search}>
            <div className = "row mb-2">
              <div className = "col-12 mb-2">
                <select
                    className = "form-control border-0 py-0"
                    name = "target"
                    value = {this.state.target}
                    onChange = {this.handleChange}>
                  <option value = {null}>Tipo de servicio</option>
                  <option value = "0">Quiero Arrendar</option>
                  <option value = "1">Quiero Comprar</option>
                </select>
              </div>
              <div className = "col-12 mb-2">
                <select
                    className = "form-control border-0 py-0"
                    name = "type"
                    value = {this.state.type}
                    onChange = {this.handleChange}>
                  <option value = "">Tipo de inmueble</option>
                  {this.props.types.map(i => (
                      <option
                          key = {i.id}
                          value = {i.id}>
                        {i.name}
                      </option>
                  ))}
                </select>
              </div>
              <div className = "col-12 d-flex justify-content-between mb-2">
                <select
                    className = "form-control border-0 mr-3 py-0"
                    name = "area"
                    value = {this.state.area}
                    onChange = {this.handleChange}>
                  <option value = "">Area</option>
                  {this.props.areas.map(i => (
                      <option
                          key = {i.id + 'area'}
                          value = {i.area_from + ',' + i.area_to}>
                        {i.area_from + ' - ' + i.area_to + ' m²'}
                      </option>
                  ))}
                </select>
                <input
                    name = "id"
                    value = {this.state.id}
                    onChange = {this.handleChange}
                    className = "form-control border-0"
                    placeholder = "Código"
                    type = "number"
                />
              </div>
              <div className = "col-12 mb-2">
                <select
                    className = "form-control border-0 py-0"
                    name = "city"
                    value = {this.state.city}
                    onChange = {this.handleChange}>
                  <option value = "">Ciudad</option>
                  {this.props.cities.map(i => (
                      <option
                          key = {i.id + 'city'}
                          value = {i.id}>
                        {i.name}
                      </option>
                  ))}
                </select>
              </div>
              <div className = "col-12 mb-2">
                <select
                    className = "form-control border-0 py-0"
                    name = "price"
                    value = {this.state.price}
                    onChange = {this.handleChange}>
                  <option value = "">Precios</option>
                  {(this.state.target === "1" ? this.props.sellPrices : this.props.rentPrices).map(i => (
                      <option
                          key = {i.id + 'prices'}
                          value = {i.price_from + ',' + i.price_to}>
                        { `$${formatPrice(i.price_from)} - $${formatPrice(i.price_to)}`}
                      </option>
                  ))}
                </select>
              </div>
              {showMore && (
                  <div className = "col-12 mb-2">
                    <select
                        className = "form-control border-0 py-0"
                        name = "rooms"
                        value = {this.state.rooms}
                        onChange = {this.handleChange}>
                      <option value = "">Habitaciones</option>
                      <option value = "1">0 a 1</option>
                      <option value = "2">2</option>
                      <option value = "3">3</option>
                      <option value = "4">4 o más</option>
                    </select>
                  </div>
              )}
              {showMore && (
                  <div className = "col-12 mb-2">
                    <select
                        className = "form-control border-0 py-0"
                        name = "parking"
                        value = {this.state.parking}
                        onChange = {this.handleChange}>
                      <option value = "">Parqueadero</option>
                      <option value = {true}>Con Parqueadero</option>
                      <option value = {false}>Sin Parqueadero</option>
                    </select>
                  </div>
              )}
              {showMore && (
                  <div className = "col-12 mb-2">
                    <select
                        className = "form-control border-0 py-0"
                        name = "condo"
                        value = {this.state.condo}
                        onChange = {this.handleChange}>
                      <option value = "">Conjunto cerrado</option>
                      <option value = {true}>Sí</option>
                      <option value = {false}>No</option>
                    </select>
                  </div>
              )}
              <div className = "col-12 mb-2 d-flex justify-content-start">
                <button className = "btn btn-secondary w-50" type = "submit">
                  Buscar
                </button>
                <button className = "btn btn-link ml-3 ml-lg-5" type = "button" onClick = {this.Toggle}>
                  {this.state.showMore ? 'Menos opciones' : 'Más opciones'}
                </button>
              </div>

            </div>

          </form>
        </div>
    );
  }
}

function mapDispatchToProps(dispatch){
  return bindActionCreators(
      {
        loadProperties,
        loadPropertiesTypes,
        loadPropertiesRentPrices,
        loadPropertiesSellPrices,
        loadPropertiesAreas,
        loadCities
      },
      dispatch
  );
}

function mapStateToProps(state){
  const {
    types,
    areas,
    rentPrices,
    sellPrices,
    params
  } = state.PropertiesReducer;
  const {cities} = state.AddressReducer;
  return {
    types,
    rentPrices,
    areas,
    sellPrices,
    cities,
    params: {...params}
  };
}

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(SearchLandingComponent)
);
