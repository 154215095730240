import React, {Component} from 'react';
import '../../Styles/_button.scss';
import Menu from './MenuComponent';
import Leasing from './LeasingComponent';

class Services extends Component <{},{}>{
  constructor(props){
    super(props);
    this.state = {renderComponent: <Leasing/>};
  }

  render(){
    return (
        <div className = "container py-5">
          <div className = "row">
            <div className = "col-12 col-lg-4 d-flex justify-content-start">
              <Menu toggleComp = {this.renderComponent}/>
            </div>
            <div className = "col-12 col-lg-8 pt-3 pt-lg-0">
              {this.state.renderComponent}
            </div>
          </div>
        </div>
    );
  }

  renderComponent = (newComponent) => {
      this.setState((prevState) => {
        return {...prevState, renderComponent: newComponent}
      });
  }
}

export default Services;
