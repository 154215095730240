import React, { Component } from 'react';
import './OurValues.scss';
import OurValuesItems from "../../Utils/OurValuesItems";

class OurValuesComponent extends Component{
    render() {
        return(
            <div className="container">
                <div className="row p-5">

                    <h2 className="values-title font-weight-bold">
                        Nuestros Valores
                    </h2>

                    <div className = "ourValues-section row justify-content-center align-items-center">
                        {OurValuesItems.map(i => (
                            <div
                                key = {i.id}
                                className = "ourValues-section-item col-lg-4">
                                <h2 className = "mb-3">
                                    <b> {i.title} </b>
                                </h2>
                                <p>{i.description}</p>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        );
    }
}

export default OurValuesComponent;