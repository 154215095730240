import React, {Component} from "react";
import Lightbox from "react-image-lightbox";
import RequestsService from "../../Services/RequestsService";
import LoadingComponent from "../Shared/Loadig/LoadingComponent";

class RepairDetailItemComponent extends Component {

    state = {
        isOpen: false,
        photos: [],
        photoIndex: -1,
        showLoading: true,
    }

    componentDidMount() {
        let {Cons_Web} = this.props.detail;
        RequestsService.getRepairImages(Cons_Web)
            .then(items => {
                this.setState({ photos: items, showLoading: false })
            })
            .catch(() => {
                this.setState({ showLoading: false })
            })
    }

    render() {
        let { detail } = this.props;
        let { isOpen, photos, photoIndex, showLoading } = this.state

        return (
            <div className={"row"}>
                <div className="col-12">
                    <table className={"table table-sm table-striped table-bordered"}>
                        <thead>
                        <tr>
                            <th>Cons Web</th>
                            <th>Descripción</th>
                            <th>Nombre de reparación</th>
                        </tr>
                        </thead>
                        <tbody>
                            {detail["Desc_Daño"].split("\n").map(i => (
                                <tr>
                                    <td>{detail.Cons_Web}</td>
                                    <td>{i.split("-")[0]}</td>
                                    <td>{i.split("-")[1]?i.split("-")[1]:detail.Nombre_Arreglo_Reparacion}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                <div className="col-12 d-flex justify-content-center">
                    <LoadingComponent show={showLoading}/>
                </div>
                {
                    !showLoading &&
                    <div className="col-12">
                        <h6>Evidencias</h6>
                        {
                            photos.map((i, index) => (
                                <img className="p-1 clickable"
                                     key={`photo_${index}`}
                                     onClick={() => this.setState({isOpen: true, photoIndex: index})}
                                     src={i.file_path} alt="..."
                                />)
                            )
                        }
                        {
                            isOpen && (
                                <Lightbox reactModalStyle={{overlay: {'zIndex': 2000}}}
                                          mainSrc={photos[photoIndex].file_path}
                                          nextSrc={photos[(photoIndex + 1) % photos.length]}
                                          prevSrc={photos[(photoIndex + photos.length - 1) % photos.length]}
                                          onCloseRequest={() => this.setState({isOpen: false})}

                                          onMovePrevRequest={() =>
                                              this.setState({
                                                  photoIndex: (photoIndex + photos.length - 1) % photos.length
                                              })
                                          }
                                          onMoveNextRequest={() =>
                                              this.setState({
                                                  photoIndex: (photoIndex + 1) % photos.length
                                              })
                                          }
                                />
                            )
                        }
                    </div>
                }
            </div>
        )
    }
}

export default RepairDetailItemComponent;