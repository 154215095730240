export default {
  N_BAÑOS: "N. Baños",
  N_ALCOBAS: "N. Alcobas",
  SALA: "Sala",
  SALA_COMEDOR: "Sala Comedor",
  COMEDOR: "Comedor",
  COMEDOR_AUX: "Comedor Auxiliar",
  COCINA: "Cocina",
  ESTUFA: "Estufa",
  HORNO: "Horno",
  GAS: "Gas",
  N_CLOSETS: "N. Closets",
  N_PATIOS: "N. Patios",
  N_VESTIER: "N. Vestier",
  N_GARAJES: "N. Garajes",
  LINEAS_TELEFONICAS: "Lineas Telefónicas",
  ALCOBA_SER: "Alcoba Servicio",
  BALCON: "Balcon",
  BIBLIOTECA: "Biblioteca",
  HALL: "Hall",
  MEZANINE: "Mezanine",
  MIRADOR: "Mirador",
  TERRAZA: "Terraza",
  ZONA_ROPAS: "Zona Ropas",
  BAÑO_CABINA: "Baño Cabina",
  BAÑO_SERVICIO: "Baño Servicio",
  CUARTO_UTIL: "Cuarto Útil",
  ASCENSOR: "Ascensor",
  JUEGOS: "Juegos",
  JACUZZI: "Jacuzzi",
  PARQUEADERO: "Parqueadero",
  PARQUEADERO_CUB: "Parqueadero Cubierto",
  PORTERIA: "Portería",
  PORTERIA_HORAS: "Portería  Horas",
  PISCINA: "Piscina",
  SALON_SOCIAL: "Salón Social",
  SAUNA: "Sauna",
  TURCO: "Turco",
  ZONAS_VERDES: "Zonas Verdes",
  ZONA_DEPORTIVA: "Zona Deportiva",
  NIVEL_NUM: "Nivel #",
  TV_CABLE: "TV por cable",
  PISOS: "Pisos",
  NOMBRE_URB: "Nombre Urbanización",
  AGUA_CALIENTE: "Agua caliente",
  ADMIN_VALOR: "Administracion valor",
  NUMERO_TELEFONICO: "Número telefónico",
  UNIDAD_CERRADA: "Unidad Cerrada",
  SALON: "SALON",
  TIPO_INMUEBLE: "Tipo Inmueble",
  FOTOS: "Fotos"
}