import {get} from '../NetworkLayer/Index';
import {URL} from '../Utils/Config';
import AuthService from "./AuthService";

// let user = JSON.parse(localStorage.getItem('user'));
// const initialState = user ? {
//     loggedIn: true,
//     user
// } : {};

export default class ContractService {
    static getContracts(id: ? string, owner = false, page = 0): Promise<Array<any>> {
        const params = {
            size: 10,
            page
        };

        console.log("params ", params);

        if (owner) {
            params.ownerDocFilter = id;
        } else {
            params.documentFilter = id;
            if (AuthService.getDocument()) {
                params.documentFilter = AuthService.getDocument();
            }
        }

        return get(URL.contracts.search, params).then(data => {
            const {result, items, total_pages} = data.data;
            if (result === "OK") {
                return {
                    contracts: items.filter(contract => contract.contract_id),
                    total_pages,
                };
            }
            return [];
        }).catch(error => {
            console.error(error)
        });
    }

    static getDocs(id: string, docTypeId: number = 0, page: number, contract?: String, parent_id?: number, spaceKey?: String, invRequest?: String) {
        const params = {
            entity_id: id,
            folder_id: 2,
            size: 1000,
            contract,
            parent_id,
            space_key: spaceKey,
            inv_request: invRequest
        };
        if (docTypeId) {
            params.doc_type_id = docTypeId
        }
        if (page) {
            params.size = 28;
            params.page = page;
        }
        return get(URL.content.search, params).then(res => {
            const {
                result,
                items,
                total_pages
            } = res.data;
            if (result === 'OK') {
                return {items, total_pages};
            } else {
                return [];
            }
        });
    }
}
