// @flow
import React, { Component } from "react";
import LogoUser from "../../Assets/Images/user.png";
import FormComponent from "../Shared/FormComponent/FormComponent";
import AuthService from "../../Services/AuthService";
import { toast } from "../../Services/AlertService";
import { setCurrentUser } from "../../Redux/ActionTypes";
import type { RouterHistory } from "react-router-dom";
import LogoHouse from "../../Assets/Images/home.png";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

type Props = {
  history: RouterHistory,
};

class LoginClientComponent extends Component<Props> {
  loginTenant = (id: number, password: number) => {
    AuthService.login({ login: id, password }).then((res: any) => {
      let type, message;
      if (res.success) {
        type = "success";
        message = "Login Successful";
        this.props.setCurrentUser(res);
        this.props.history.push({
          pathname: "/inquilino",
        });
      } else {
        type = "error";
        message = res.msg;
      }
      toast(message, type);
    });
  };

  loginOwner = (login: number, password: number) => {
    AuthService.login({ login, password }).then((res: any) => {
      let type, message;
      if (res.success) {
        type = "success";
        message = "Login Successfull";
        this.props.history.push({
          pathname: "/contratos",
        });
      } else {
        type = "error";
        message = res.msg;
      }
      toast(message, type);
    });
  };

  render() {
    return (
      <div className="container">
        <div className="row p-5">
          <div className="col-12 col-md-6 mb-4 mb-md-0 d-flex justify-content-center align-items-center">
            <FormComponent
              title="Clientes"
              logo={LogoUser}
              email={false}
              click={this.loginTenant}
            />
          </div>

          <div className="col-12 col-md-6 d-flex justify-content-center">
            <FormComponent
              title="Propietarios"
              logo={LogoHouse}
              email={false}
              click={this.loginOwner}
            />
          </div>
        </div>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setCurrentUser,
    },
    dispatch
  );
}
export default connect(
  null,
  mapDispatchToProps
)(LoginClientComponent);
