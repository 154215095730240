import React, {Component} from 'react';
import MainImage from '../../Assets/Images/serviciosPuplicos.jpg';


class SystemAssist extends Component <{},{}>{
  render(){
    return (
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 col-lg-8 mt-5">
              <ul>
                <li className="text-justify">Contamos con un excelente servicio al momento de ingresar y promocionar sus inmuebles en los diferentes medios de comunicación como pagina web, Facebook, twitter, medio impreso y asesoría personalizada dentro de la agencia.</li>
                <br/>
                <li className="text-justify">contamos con los equipos adecuados para el buen rendimiento de la empresa y mayor agilidad para atender nuestros clientes.</li>
              </ul>
            </div>
            <div className="col-12 col-lg-6">
              <img className = "services-image img-fluid mb-5 mx-5 w-75" src = {MainImage} alt = "not found"/>
            </div>
          </div>
        </div>
    );
  }
}

export default SystemAssist;
