import React, {Component, Fragment} from 'react';
import Dropzone from "react-dropzone";

class DropZoneUploader extends Component{

  render(){
    const {files, cancel, uploadFile, onCancel, onDrop} = this.props;
    return (
        <Fragment>
          <div className = "row mt-3">
            <div className = "col-1"/>
            < Dropzone onDrop = {onDrop}
                       onFileDialogCancel = {onCancel && onCancel.bind(this)}>
              {({getRootProps, getInputProps}) => (
                  <div {...getRootProps()} className = "col-10 doc-uploader">
                    <input {...getInputProps()} />
                    {files.length ?
                        files.map(file => (
                        <li key = {file.name}>
                          {file.name} - {file.size} bytes
                        </li> )) :
                        (
                            <span>Arrastre archivos nuevos aquí, o presione click para seleccionarlos</span>)
                    }
                  </div>
              )}
            </Dropzone>
          </div>
          {files.length > 0 && (
              <div className = "row">
                <div className = "col-10 pt-2 pr-0 d-flex justify-content-end">
                  <button className = "btn btn-sm btn-secondary"
                          onClick = {() => cancel()}>
                    Cancelar
                  </button>
                </div>
                <div className = "col-1 pt-2 pl-0 d-flex justify-content-end">
                  <button className = "btn btn-sm btn-secondary"
                          onClick = {() => uploadFile()}>
                    Subir
                  </button>
                </div>
              </div>)}

        </Fragment>
    );
  }
}

export default DropZoneUploader;
