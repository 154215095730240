import {get, post} from '../NetworkLayer/Index';
import {URL} from '../Utils/Config';
import {uuidV4} from "../Utils/Utilities";

export default class RequestsService{
  static getInfo(id: string){
    const params = {
      docFilter: id,
    };
    return get(URL.propertyRequest.list, params).then(data => {
      const {
        result,
        items,
      } = data.data;
      if(result === "OK" && items.length){

        return items;

      } else{

        return []

      }
    }).catch(error => {
      console.error(error)
    });
  }

  static getRequestDocs(id: string, reqId: number, docTypeId: number = 0, size: number = 21, page: number = 1){
    const params = {
      entity_id: reqId,
      folder_id: 1,
      size,
      page
    };
    if(docTypeId){
      params.doc_type_id = docTypeId
    }
    return get(URL.content.search, params).then(res => {
      const {
        result,
        items,
        totalPages
      } = res.data;
      if(result === "OK"){

        return {items, totalPages};

      } else{

        return []

      }
    }).catch(error => {
      console.error(error)
    });
  }

  static getRequestComments(reqId: number){
    const params = {
      request: reqId
    };
    return get(URL.propertyRequest.comment.list, params).then(data => {
      const {
        result,
        items,
      } = data.data;
      if(result === "OK"){

        return items;

      } else{

        return []

      }
    }).catch(error => {
      console.error(error)
    });
  }

  static addRequestComment(reqId: number, comment: string){
    const params = {
      prop_request: reqId,
      comment
    };
    return post(URL.propertyRequest.comment.add, params).then(data => {
      const {
        result,
        requests,
      } = data.data;
      if(result === "OK"){

        return requests[0];

      } else{

        return []

      }
    }).catch(error => {
      console.error(error)
    });
  }

  static uploadFile(params, url){
    if (!url) {
      url = URL.content.upload;
    }
    return post(url, params).then(data => {
      const {
        result,
        requests,
      } = data.data;
      if(result === "OK"){

        return requests[0];

      } else{

        return []

      }
    }).catch(error => {
      console.error(error)
    });
  }

  static sendRepairRequest(descriptions, params){
    let data = {...params, descriptions: []};

    let form = new FormData();
    let descriptionFiles = []

    for (let [index, {description, files, repairCode}] of descriptions.entries()) {
      data.descriptions.push({
        description,
        repairCode
      })
      for (let file of files) {
        const extension = file.name.split('.').pop();
        let name = `${uuidV4()}.${extension}`;
        form.append("files", file, name)
        descriptionFiles.push({
          index,
          name
        });
      }
    }

    form.append("model", JSON.stringify(data));
    form.append("descriptionFiles", JSON.stringify(descriptionFiles))

    return post(URL.repair, form).then(res => {
      if(res.result === "OK"){
        return {
          success: true,
          res: {
            msg: "Su solicitud ha sido enviada, prontamente lo estaremos contactando."
          }
        }
      } else{
        return {
          success: false,
        }
      }
    })
        .catch(error => {
          console.error(error);
        });
  }
  static sendPublicServices(files, params){
    var form = new FormData();
    files.forEach(file => {
      form.append("file", file, uuidV4());
    });
    form.append("model", JSON.stringify(params));
    return post(URL.publicServices, form).then(res => {
      if(res.result === "OK"){
        return {
          success: true,
          res: {
            msg: "Su solicitud ha sido enviada, prontamente lo estaremos contactando."
          }
        }
      } else{
        return {
          success: false,
        }
      }
    })
        .catch(error => {
          console.error(error);
        });

  }

  static getRepairCodes() {
    return get(URL.repairCodes).then(data => {
      const { result, items } = data.data;
      return result === "OK" ? items : [];
    }).catch(error => {
      console.error(error)
    });
  }

  static getRepairs(contract) {
    return get(URL.repairs.concat("?contract=").concat(contract)).then(data => {
      const { result, items } = data.data;
      return result === "OK" ? items : [];
    }).catch(error => {
      console.error(error)
    });
  }

  static getRepairImages(detailId) {
    return get(URL.repairImages.concat(detailId)).then(data => {
      const { result, items } = data.data;
      return result === "OK" ? items : [];
    }).catch(error => {
      console.error(error)
    });
  }
}
