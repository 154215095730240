// @flow
import React, {Component} from 'react';
import LogoUser from '../../Assets/Images/user.png';
import FormComponent from '../Shared/FormComponent/FormComponent';
import AuthService from '../../Services/AuthService';
import {toast} from '../../Services/AlertService';
import type {RouterHistory} from 'react-router-dom';

type Props = {
  history: RouterHistory
};
class LoginRequestComponent extends Component<Props> {
  login = (id: number, password: number) => {
    AuthService.login({login: id, password}).then((res: any) => {
      let type, message;
      if(res.success){
        type = 'success';
        message = 'Login Successful';
        this.props.history.push({
          pathname: '/consultas'
        });
      } else{
        type = 'error';
        message = res.msg;
      }
      toast(message, type);
    });
  };

  render() {
    return (
      <div className="p-5 d-flex justify-content-center align-items-center">
        <FormComponent
          title="Solicitud"
          email = {false}
          logo={LogoUser}
          click={this.login}
        />
      </div>
    );
  }
}

export default LoginRequestComponent;
