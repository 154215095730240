// @flow
import {get, post} from '../NetworkLayer/Index';
import {URL} from '../Utils/Config';
import {uuidV4} from "../Utils/Utilities";

    export default class SharedService {
        static sendContactUs(params: any) {
            return get(URL.contactRequest.add, params)
                .then(res => {
                    const {
                        data
                    } = res;
                    if (data.result === "OK") {
                        return {
                            success: true,
                            res: {
                                msg: "Su solicitud ha sido enviada, prontamente lo estaremos contactando."
                            }
                        }
                    } else {
                        return {
                            success: false,
                            res: {
                                msg: data.msg
                            }
                        }
                    }
                })
                .catch(error => {
                    console.error(error);
                });
        }
        static sendInterestAlert(params: any) {
            return get(URL.contactRequest.add, params)
                .then(res => {
                    const {
                        data
                    } = res;
                    if (data.result === "OK") {
                        return {
                            success: true,
                            res: {
                                msg: "Su solicitud ha sido enviada, prontamente lo estaremos contactando."
                            }
                        }
                    } else {
                        return {
                            success: false,
                            res: {
                                msg: data.msg
                            }
                        }
                    }
                })
                .catch(error => {
                    console.error(error);
                });
        }
        static sendPaymentInfo(params: any) {
            return get(URL.payment + `/${params.id}/generate_url`)
                .then(res => {
                    const {
                        data,
                    } = res;
                    if (data.result === "OK") {
                        window.open(data.payment_url.url, '_blank');
                        return {
                            success: true,
                            res: {
                                status: "in progress",
                                msg: "Serás dirigido al sitio de pago"
                            }
                        }
                    } else {
                        return {
                            success: false,
                            res: {
                                status: "error",
                                msg: "Un error ha ocurrido, intente nuevamente más tarde"
                            }
                        }
                    }
                })
                .catch(error => {
                    console.error(error);
                });
        }
        static sendPaymentDocument(file, params: any) {
            var form = new FormData();
            form.append("file", file, uuidV4());
            form.append("model", JSON.stringify(params));
            return post(URL.registerPayment, form)
                .then(res => {
                    const {
                        data
                    } = res;
                    if (data.result === "OK") {
                        return {
                            success: true,
                            res: {
                                msg: "Su solicitud ha sido enviada, prontamente lo estaremos contactando."
                            }
                        }
                    } else {
                        return {
                            success: false,
                            res: {
                                msg: data.msg
                            }
                        }
                    }
                })
                .catch(error => {
                    console.error(error);
                });
        }
    }
