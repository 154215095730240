import React, {Component} from 'react';
import MainImage from '../../Assets/Images/DSC_3436.jpg';

class Filing extends Component <{},{}>{
  render(){
    return (
        <div className="container-fluid">
          <div className="row">
            {/*<div className="col-12 col-lg-6">*/}
            {/*  <img className = "services-image img-fluid mb-5" src = {MainImage} alt = "not found"/>*/}
            {/*</div>*/}
            <div className="col-12">
              <ul>
                <li className="text-justify">Buscando la manera de mejorar nuestro trabajo y atención al cliente contamos con un archivo digital el cual permite consultar los contratos que se encuentran activos con la empresa y descargar por la página web los documentos que son de su interés como: consignaciones, servicios públicos, prediales, reparaciones y administraciones.</li>
                <li className="text-justify">Para obtener los documentos se debe acercar a la empresa donde se le asignará una clave con la cual podrá acceder a la información.</li>
              </ul>
            </div>
          </div>
        </div>
    );
  }
}

export default Filing;