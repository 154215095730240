import React, {Component, Fragment} from 'react';
import Navbar from '../Shared/LoggedNavbarComponent';
import type {RouterHistory} from "react-router-dom";
import {withRouter} from "react-router-dom";
import Info from './RequestInfoComponent';
import Documents from '../Shared/DocumentsComponent/DocumentsComponent';
import {bindActionCreators} from "redux";
import {changeCurrentRequest, loadReqComments, loadReqDocuments, loadReqInfo} from "../../Redux/ActionTypes";
import {connect} from "react-redux";
import RequestsService from "../../Services/RequestsService";
import {toast} from "../../Services/AlertService";
import AuthService from "../../Services/AuthService";
import {validate} from "../../Utils/Utilities";


type Props = {
  history: RouterHistory
};

class RequestCheckComponent extends Component<Props, state>{

  state = {
    mainLoad: true,
    currentContract: 0,
    fatherFolder: '',
    fatherFolderId: 0,
    contractId: 0,
    userId: AuthService.getUserId(),
    token: AuthService.getToken()
  };

  logOut(){
    AuthService.removeLocalStorageItems();
    this.props.history.push({
      pathname: '/consultar_solicitud'
    });
  }

  uploadFile(file){
    const formData = new FormData();
    formData.append('file', file);
    formData.append('model', JSON.stringify({
      doc_type_id: this.state.fatherFolderId, entity_id: this.state.userId, folder_id: 1, token: this.state.token
    }));
    RequestsService.uploadFile(formData).then(res => {
      this.props.loadReqDocuments(this.state.currentContract, this.props.current_request.id, this.state.fatherFolderId);
      toast('Documento agregado exitosamente.', 'success');
    }).catch(() => {
      toast('Intentelo nuevamente.', 'error');
    });
  }

  sendRequestComment(comment){
    if(comment.length){
      RequestsService.addRequestComment(this.state.userId, comment).then(res => {
        this.props.loadReqComments(this.state.userId);
        toast('Comentario agregado exitosamente.', 'success');
      }).catch(() => {
        toast('Intentelo nuevamente.', 'error');
      });

    }
  }

  loadRoot(){
    this.setState(previousState => {
      return {...previousState, mainLoad: true};
    });
    this.props.loadReqDocuments(this.state.currentContract, this.props.current_request.id);
  }

  loadFromFolder(folderId, name){
    this.setState(previousState => {
      return {...previousState, mainLoad: false, fatherFolder: name, fatherFolderId: folderId};
    }, () => {
      this.props.loadReqDocuments(this.state.currentContract, this.props.current_request.id, folderId);
    });
  }

  openDoc(file){
    if(file.icon_type === "Folder"){
      return this.loadFromFolder(file.doc_type_id, file.name)
    } else{
      const link = document.createElement('a');
      document.body.appendChild(link);
      link.href = file.content_path;
      link.click();
      document.body.removeChild(link);
    }
  }

  componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS): void{
    if(prevProps.current_request !== this.props.current_request) {
      this.props.loadReqDocuments(this.props.current_request.id, this.state.userId);
      this.props.loadReqComments(this.props.current_request.id);
    }
  }

  componentDidMount(){
    const id = AuthService.getLogin();
    this.props.loadReqInfo(id);
    validate();
  }

  render(){
    const {fatherFolder, mainLoad} = this.state;
    const {information, current_request, comments, documents, pages, changeCurrentRequest} = this.props;
    return (
        <Fragment>
          <Navbar action = {this.logOut.bind(this)}/>
          <div className = "container">
            <div className = "row pt-2">
              <Info info = {information}
                    request = {current_request}
                    changeRequest={changeCurrentRequest}
                    comments = {comments}/>
            </div>
            <div className = "row pt-2">
              <Documents request = {true}
                         folder = {fatherFolder}
                         documents = {documents}
                         contract = {current_request && current_request.id}
                         pages = {pages}
                         loadRoot = {this.loadRoot.bind(this)}
                         openDoc = {this.openDoc.bind(this)}
                         mainLoad = {mainLoad}
                         comments = {comments}
                         sendComment = {this.sendRequestComment.bind(this)}
                         uploadFile = {this.uploadFile.bind(this)}/>
            </div>
          </div>
        </Fragment>
    );
  }
}


function mapDispatchToProps(dispatch){
  return bindActionCreators({loadReqInfo, loadReqDocuments, loadReqComments, changeCurrentRequest}, dispatch);
}

function mapStateToProps(state){
  const {information, current_request, documents, comments, pages} = state.InformationReducer;
  return {information, current_request, documents, comments, pages};
}

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(RequestCheckComponent));
