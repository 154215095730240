import React, {Component} from 'react';
import {Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';

class ModalComponent extends Component <{}, {}>{

  render(){
    const {header, body, onClose, footer, size} = this.props;

      const closeBtn = <button className="close" onClick={onClose}>&times;</button>;

      return (
        <div>
          <Modal isOpen = {true} toggle = {onClose} size={size ? size : 'lg'}>
            <ModalHeader close={closeBtn} toggle = {onClose}> {header}</ModalHeader>
            <ModalBody>
              {body}
            </ModalBody>
            {footer && <ModalFooter>
              {footer}
            </ModalFooter>}
          </Modal>
        </div>
    );
  }

}

export default ModalComponent;
