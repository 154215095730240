import {
  get,
  post
} from '../NetworkLayer/Index';
import {URL} from '../Utils/Config';
import parallel from 'async/parallel';

export default class PropertiesService {
  static loadProperties(params: any) {
    params.size = 6;
    return get(URL.property.search, params)
      .then(res => {
        return {
          list: res.data.items,
          pages: res.data.total_pages,
          params: params
        };
      })
      .catch(error => {
        console.error(error);
      });
  }

  static getEmployees(role){
    return get(URL.employees, {role}).then(res => res);
  }

  static getProperty(id: number) {
    return new Promise((resolve, reject) => {
      parallel([
        function (cb) {
          get(URL.property.search, {id}).then(res => {
            var data = res.data;
            if (data.result === "OK" && data.items.length) {
              cb(null, data.items[0]);
            } else {
              cb(new Error(data.msg || "No se encontró la propiedad."));
            }
          }).catch(error => {
            cb(error);
          });
        },
        function (cb) {
          get(URL.property.photos, {property: id}).then(res => {
            var data = res.data;
            if (data.result === "OK") {
              cb(null, data.keys);
            } else {
              cb(new Error(data.msg));
            }
          }).catch(error => {
            cb(error);
          });
        },
        function (cb) {
          get(URL.property.measurements, {property_id: id}).then(res => {
            var data = res.data;
            if (data.result === "OK") {
              cb(null, data.items);
            } else {
              cb(new Error(data.msg));
            }
          }).catch(error => {
            cb(error);
          });
        }
      ], (err, res) => {
        if(err) {
          return reject(err);
        }
        var property = res[0];
        property.photos = res[1];
        property.measurements = res[2];
        resolve(property);
      });
    });
  }
  static loadPropertiesTypes() {
    return get(URL.types)
      .then(res => {
        return res.data.items;
      })
      .catch(error => {
        console.error(error);
      });
  }
  static loadPropertiesRentPrices() {
    return get(URL.prices.rent)
      .then(res => {
        return res.data.items;

      })
      .catch(error => {
        console.error(error);
      });
  }
  static loadPropertiesSellPrices() {
    return get(URL.prices.sell)
      .then(res => {
        return res.data.items;

      })
      .catch(error => {
        console.error(error);
      });
  }
  static loadPropertiesAreas() {
    return get(URL.areas)
      .then(res => {
        return res.data.items;

      })
      .catch(error => {
        console.error(error);
      });
  }

  static registerProperty(registerData) {
    return post(URL.property.register, registerData).then(res => {
      if (res.result === "OK") {
        return {
          success: true,
          res: {
            msg: 'Formulario enviado con exito !!!'
          }
        }
      }
      if (res.result === "ERROR") {
        return {
          success: true,
          res: {
            msg: res.msg
          }
        }
      }
    })
  }
}
